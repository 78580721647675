import React, { useState, useContext, useEffect } from 'react';
import styles from './styles/ActiveTicket.module.scss';
import { GetTickets, UploadTicketPhotos } from '../../services/authServices';
import { ThemeContext } from '../../contexts/ThemeContext';
import { EmployeeContext } from '../../contexts/EmployeeContext';
import { CreateTicket } from '../../services/authServices';
import cx from 'classnames';
import ToastNotification from '../../common/components/ToastNotification';
import { useToast } from '../../contexts/ToastContext';


interface Ticket {
    Id: number | null,
    CreatedBy: number | null,
    DateCreated: string | null,
    Status: string,
    Description: string,
    Photos: Array<any>     
}

interface Props {
    ticket: Ticket,
    employees: any,
    cancelNewTicket: Function,
    updateAllTickets: Function
}

interface ColorCodes {
    "Resolved" : string,
    "Ready for Review" : string,
    "Unresolved" : string,
    "Select Status" : string
}

const ActiveTicket: React.FC<Props> = ({ ticket, employees, cancelNewTicket, updateAllTickets }) => {
    const [currentTicket, setCurrentTicket] = useState(ticket);
    const [expandedImage, setExpandedImage] = useState(null);    
    const [newImages, setNewImages] = useState<any>([])
    const [employee, setEmployee] = useState(employees.find((employee: any) => employee.id == ticket.CreatedBy))
    const { showToast } = useToast();
    const theme = useContext(ThemeContext);

    const colorCodes: ColorCodes = {
        "Resolved" : "#2e9d2e",
        "Ready for Review" : "#40bad3",
        "Unresolved" : "#ee4a11",
        "Select Status" : "#b0b0b0" 
    }    

    const updateTicket = (value: string, type: keyof Ticket) => {
        setCurrentTicket({
            ...currentTicket,
            [type] : value
        })
    }

    const createTicket = async () => {        
        if (currentTicket.Description.trim() !== "" && currentTicket.Status !== "Select Status") {            
            let res: any = await CreateTicket(currentTicket);                
            await UploadTicketPhotos(newImages, res);
            showToast("Loading", "Loading")

            setCurrentTicket({
                ...currentTicket,
                Id: res
            })
            updateAllTickets({ ...currentTicket, Id: res });     
            
            showToast("Ticket successfully created", "Success")
        }
    }

    const fileDialog = () => {        
        var input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.multiple = true;
        input.onchange = (_) => {
            if (input.files) {
                let files: any = Array.from(input.files);
                let newImageUrls = files.map((file: any) => URL.createObjectURL(file));
                setNewImages([...newImages, ...files]);
                setCurrentTicket({
                    ...currentTicket,
                    Photos: [...currentTicket.Photos, ...newImageUrls]
                })
            }
        };
        input.click();
    }

    useEffect(() => {
        console.log(newImages)
    }, [newImages])

    useEffect(() => {
        setEmployee(employees.find((employee: any) => employee.id == ticket.CreatedBy))
    }, [ticket])

    useEffect(() => {
        setCurrentTicket(ticket)
    }, [ticket])     

    return (
        <div className={cx(styles.card, theme.darkMode && styles.darkMode)} style={{ borderLeft: `solid 3px ${colorCodes[currentTicket.Status as keyof ColorCodes]}` }}>
            <div className={styles.topDiv}>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <h2 style={{ marginRight: '5px' }}>{currentTicket.Id == null ? "New Ticket" : `Ticket No. ${currentTicket.Id}`} -</h2>
                        <select 
                            className={cx(styles.statusSelect)} 
                            value={currentTicket.Status}
                            style={{ color: `${colorCodes[currentTicket.Status as keyof ColorCodes]}` }}
                            onChange={(e) => updateTicket(e.currentTarget.value, "Status")}
                        >
                            <option selected={true} disabled={true} value="Select Status">Select Status</option>
                            <option value="Unresolved">Unresolved</option>
                            <option value="Ready for Review">Ready for Review</option>
                            <option value="Resolved">Resolved</option>
                        </select>
                    </div>
                    <p>Created: {ticket.DateCreated}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: '10px' }}>{employee.name}</p>
                    <img src={employee.photo} className={styles.employeePhoto} alt="employee photo" />
                </div>
            </div>
            <div className={cx(styles.middleDiv, theme.darkMode && styles.darkMode)}>
                <h3>Description</h3>
                <textarea 
                    value={currentTicket.Description} 
                    placeholder="Type a helpful description of the issue here" 
                    onChange={(e) => updateTicket(e.currentTarget.value, "Description")}
                />                
            </div>
            <div className={cx(styles.imageDiv, theme.darkMode && styles.darkMode)}>
                <h3>Images</h3>
                {
                    currentTicket.Id == null && (
                        <p>Add images that help explain the issue you're having</p>
                    )
                }
                <div className={styles.imageDisplay}>
                  <div className={cx(styles.addImagePrompt, theme.darkMode && styles.darkMode)} onClick={() => fileDialog()}>
                    <p>Add Image +</p>
                  </div>
                  {
                    currentTicket.Photos.length > 0 && (
                        currentTicket.Photos.map((photo: any) => {                            

                            return (
                                <img src={photo} alt="ticket-photo" key={photo} onClick={() => setExpandedImage(photo)}/>
                            )
                        })
                    )
                  }
                </div>        
            </div>
            {
                currentTicket.Id == null && (
                    <div className={cx(styles.formButtons, theme.darkMode && styles.darkMode)}>
                        <button onClick={() => createTicket()} style={{ backgroundColor: '#000', color: "#fff" }}>Create</button>
                        <button onClick={() => cancelNewTicket()} style={{ backgroundColor: theme.darkMode ? 'gray' : '#cacaca' }}>Cancel</button>
                    </div>
                )
            }
            {
                expandedImage && (
                    <div className={styles.expandedImageContainer} onClick={() => setExpandedImage(null)}>
                        <img src={expandedImage} alt="expanded-image"/>
                    </div>
                )
            }
            <ToastNotification />
        </div>
    )
}

export default ActiveTicket;