import React, { useContext } from 'react'
import styles from './styles/DashboardDemo.module.scss'
import { ThemeContext } from '../../contexts/ThemeContext'
import cx from 'classnames'
import { PyramidEmbedContainer } from "@pyramid-embed/embed-react";

const DashboardDemoView = () => {
  const theme = useContext(ThemeContext);

  const options = {
    contentId: 'a33d3e76-28fd-4d0d-baf2-e4e0b602fc2a',
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)} id="scrollable-container">
      <PyramidEmbedContainer
        host="https://pyramiddach.pyramidanalytics.com"
        options={options}
        style={{ width: "100%", height: "100%" }}
        loginType="forms"
        userName="abintus_pro"
        password="demo123!"
        customData=" "
      />
      {/* <iframe src="https://pyramiddach.pyramidanalytics.com/direct/?id=a33d3e76-28fd-4d0d-baf2-e4e0b602fc2a"/> */}
    </div>
  )
}

export default DashboardDemoView
