import React, { useState, useContext, useEffect } from 'react'
import styles from "./styles/ProcessTable.module.scss"
import { Drawer, Dialog } from '@mui/material';
import { ThemeContext } from '../../contexts/ThemeContext';
import { UploadItemFiles, DeleteItemFiles, AddProcessNote } from '../../services/authServices';
import Chevron from '../../assets/media/chevron-down.png';
import ChevronWhite from '../../assets/media/chevron-down-white.png';
import cx from 'classnames'
import FileSidebar from './FileSidebar';

interface Props {
    title: string,
    itemFiles: Array<any>,
    itemId: number,
    id: number,
    deleteSubitem: any,
    index: number | null,
    subitemLength: number,
    changeOrder: Function,
    parentStep: number
    step: number,
    setItemFilesHelper: Function,    
    deleteItems: boolean,
    notes: Array<any>,
    resetItems: Function
}   

const Subitem: React.FC<Props> = ({ title, itemFiles, itemId, id, deleteSubitem, index, subitemLength, changeOrder, parentStep, step, setItemFilesHelper, deleteItems, notes, resetItems }) => {
  const [files, setFiles] = useState<any>(itemFiles)
  const [sidebarDisplay, setSidebarDisplay] = useState<number>(-1);
  const [displayNotes, setDisplayNotes] = useState<boolean>(false); // 'notes' is what I'm calling the second layer of subitems
  const [newNote, setNewNote] = useState<string>('');
  const [itemNotes, setItemNotes] = useState<any>(notes);
  const theme = useContext(ThemeContext);
  const [noteSidebarDisplay, setNoteSidebarDisplay] = useState(Array.from(notes).fill(-1));

  useEffect(() => {
    console.log(noteSidebarDisplay)
  }, [noteSidebarDisplay])

  const addNote = async () => {
    if (newNote !== '') {
        await AddProcessNote(id, newNote)
        .then((res: any) => {            

            if (res.status == 200) {
                setNoteSidebarDisplay([...noteSidebarDisplay, -1])
                setItemNotes([...itemNotes, { Id: res.data.id, SubitemId: id, Text: newNote, Files: [] }]);
                resetItems({ Id: res.data.id, SubitemId: id, Text: newNote, Files: []})
                setNewNote('');
            }
        })
    }
  }
  
  const changeNoteSidebarDisplay = (index: number, type: number) => {
    setNoteSidebarDisplay((prevArray) => {
        let newArray = [...prevArray]
        newArray[index] = type;

        return newArray;
    })
  }

  useEffect(() => {
    setItemNotes(notes)
  }, [deleteSubitem])

  return (
    <li style={{ display: 'flex', flexDirection: 'row', marginLeft: '-10px' }}>
        <span 
          style={{ fontWeight: 'bold', marginRight: '5px', width: '35px', fontSize: '0.9rem' }}
        >
            {parentStep}.{step} - 
        </span>
        <div style={{ paddingTop: '0px' }}>        
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: displayNotes ? '10px' : 0 }}>
                <p id={styles.title} style={{ fontWeight: 500}} onClick={() => setDisplayNotes(prev => !prev)}>{title} - </p>
                <div className={cx(styles.files, theme.darkMode && styles.darkMode)}>
                    <div style={{ borderRight: `solid 1px ${theme.darkMode ? "#FFF" : "#000" }` }}><p onClick={() => setSidebarDisplay(0)}>Files</p></div>
                    <div style={{ borderRight: `solid 1px ${theme.darkMode ? "#FFF" : "#000" }` }}><p onClick={() => setSidebarDisplay(1)}>Instructions</p></div>                
                    {
                        deleteItems && (
                            <div><p onClick={() => deleteSubitem(id, "subitem")} style={{ color: `${theme.darkMode ? "#EC1818" : "rgba(189, 0, 0, 0.65" }` }}>Delete</p></div>
                        )
                    }
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            index !== 0 && (
                                <img onClick={() => changeOrder(itemId, id, step, "decrease", "subitem")} className={styles.subitemChevron} src={theme.darkMode ? ChevronWhite : Chevron} id={styles.first_chevron} />
                            )
                        }
                        {
                            index !== subitemLength - 1 && (
                                <img onClick={() => changeOrder(itemId, id, step, "increase", "subitem")} className={styles.subitemChevron} src={theme.darkMode ? ChevronWhite : Chevron} />
                            )
                        }
                    </div>
                </div>  
            </div>                    
            <div className={cx(styles.notes, displayNotes && styles.visible)} style={{ paddingLeft: '10px' }}>
                <ul>
                    {
                        itemNotes.map((note: any, index: number) => {       
                            const hideSidebar = () => {
                                setNoteSidebarDisplay(prev => {
                                    let newArray = [...prev];
                                    newArray[index] = -1;

                                    return newArray
                                })
                            }                     

                            return (
                                <>
                                    <li key={note.Id}>
                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <p style={{ fontWeight: 'bold', marginRight: '10px', width: '55px', fontSize: '0.9rem' }}>{parentStep}.{step}.{index + 1} - </p>
                                            <p style={{ marginRight: '20px' }} className={styles.noteText}>{note.Text}</p>
                                            <div className={styles.noteOptions}>
                                                <div style={{ marginRight: '10px', borderRight: `solid 1px ${theme.darkMode ? "#FFF" : "#000" }` }}>
                                                    <p style={{ fontSize: '0.75rem', color: theme.darkMode ? '#DDDDDD' : '#808080' }} onClick={() => changeNoteSidebarDisplay(index, 0)}>Files</p>
                                                </div>
                                                <div style={{ marginRight: '10px', borderRight: `solid 1px ${theme.darkMode ? "#FFF" : "#000" }` }}>
                                                    <p style={{ fontSize: '0.75rem', color: theme.darkMode ? '#DDDDDD' : '#808080' }} onClick={() => changeNoteSidebarDisplay(index, 1)}>Instructions</p>
                                                </div>
                                                <div>
                                                    <p onClick={() => deleteSubitem(note.Id, "note")} style={{ fontSize: '0.75rem', color: `${theme.darkMode ? "#EC1818" : "rgba(189, 0, 0, 0.65" }` }}>Delete</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <FileSidebar 
                                        sidebarDisplay={noteSidebarDisplay[index]} 
                                        setSidebarDisplay={hideSidebar}
                                        title={`${parentStep}.${step}.${index + 1}`}
                                        itemFiles={note.Files}
                                        id={id}
                                        itemId={itemId}
                                        subSubItemId={note.Id}
                                        setItemFilesHelper={setItemFilesHelper}
                                    />
                                </>
                            )
                        })
                    }
                    <li id={styles.noteInputItem} style={{ width: '400px' }}>
                        <textarea 
                            placeholder='New Note' 
                            className={styles.newNoteInput}
                            value={newNote}
                            onChange={(e) => setNewNote(e.currentTarget.value)}                                                     
                        />
                        <button 
                            className={styles.newNoteButton}
                            onClick={() => addNote()}
                        >
                            Add +
                        </button>
                    </li>
                </ul>
            </div>
            <FileSidebar 
                sidebarDisplay={sidebarDisplay} 
                setSidebarDisplay={setSidebarDisplay}
                title={title}
                itemFiles={files}
                id={id}
                itemId={itemId}
                subSubItemId={null}
                setItemFilesHelper={setItemFilesHelper}
            /> 
        </div>      
    </li>
  )
}

export default Subitem
