import React from 'react'

const WFAInflationView = () => {
  return (
    <div>
      Inflation Page
    </div>
  )
}

export default WFAInflationView
