import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AuthContext } from '../../contexts/AuthContext'
import { EmployeeContext } from '../../contexts/EmployeeContext'
import styles from "./styles/ProcessTable.module.scss"
import { AddProcessItems, DeleteProcessItem, ChangeProcessOrder } from '../../services/authServices'
import Chevron from '../../assets/media/chevron-down.png';
import ChevronWhite from '../../assets/media/chevron-down-white.png';
import cx from "classnames"
import { Stepper, Step, StepLabel, StepContent, Dialog } from '@mui/material'
import Subitem from './Subitem'

interface ActiveProcess {
  Id: number,
  Title: string,
  Items: Array<any>,
}

interface ProcessTableProps {
  activeProcess: ActiveProcess,
  employees: Array<any>,
  setItemsHelper: Function
  deleteItems: boolean
}

const ProcessTable: React.FC<ProcessTableProps>  = ({ activeProcess, employees, setItemsHelper, deleteItems }) => {
  const [items, setItems] = useState(activeProcess.Items);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [deleteDialog, setDeleteDialog] = useState<number>(-1);
  const [newStepVal, setNewStepVal] = useState("")
  const theme = useContext(ThemeContext);  

  const handleActiveStep = (e: any, step: number) => {    
    if (!e.target.id.includes("chevron")) {
      if (activeStep !== step) {
        setActiveStep(step)
      }
      else if (activeStep == step) {
        setActiveStep(100)
      }
    }
  }

  const createNewSubitem = async (parentItem: any) => {
    let id = parentItem.Id;
    let inputEl = document.getElementById(`new-subitem-input-${id}`) as HTMLInputElement;
    let val = inputEl.value;

    if (val.trim() !== "") { 
      let newId: number | null = null;
      let step = parentItem.Subitems.length + 1;
      await AddProcessItems(activeProcess.Id, id, val, "subitem", step)      
      .then((res: any) => {
        newId = res.data.id;
      })    

      let newItems = items.map((item: any) => {
        if (item.Id == id) {
          let subitems = item.Subitems;
          let step = subitems.length > 0 ? subitems.length + 1 : 1;
          
          return {
            ...item,
            Subitems: [...item.Subitems, { Id: newId, TableId: activeProcess.Id, ItemId: id, Title: val, Files: [], Step: step, Notes: [] }]
          }
        }
        else {
          return item
        }
      })

      inputEl.value = "";
      setItems(newItems);
      var test = activeProcess;
      activeProcess.Items = newItems
      setItemsHelper(test);
    }
  }

  const createNewStep = async () => {    
    if (newStepVal.trim() !== "") {           
      let stepNumber = items.length == 0 ? 1 : items[items.length - 1].Step + 1;
      let newId = null;
      await AddProcessItems(activeProcess.Id, null, newStepVal, "item", stepNumber)
      .then((res: any) => {
        newId = res.data.id;
      })

      let newItems = [
        ...items, 
        { 
          Author: null,
          Id: newId,
          Created: null,
          Step: stepNumber,          
          Subitems: [],
          Title: newStepVal,
          TableId: activeProcess.Id
        }
      ]
      setItems(newItems);
      var test = activeProcess;
      activeProcess.Items = newItems
      setItemsHelper(test);
      setNewStepVal("");

    }
  }  

  useEffect(() => {
    // console.log(items)
  }, [items])

  const changeOrder = (id: number, subitemId: number | null, step: number, direction: string, type: string) => {    
    if (type == "subitem") {
      let newStep = direction == "increase" ? step + 1 : step - 1;

      let newItems = [];
      for (var i = 0; i <= items.length - 1; i++) {
        var item = items[i];        
        if (item.Id == id) {
          let newSubitems = [];
          for (var j = 0; j <= item.Subitems.length - 1; j++) {
            let subitem = item.Subitems[j];

            if (subitem.Step == step) {              
              ChangeProcessOrder("subitem", subitem.Id, newStep); // no need for async here              
              newSubitems.push({ ...subitem, Step: newStep })              
            }
            else if (subitem.Step == newStep) {               
              let adjacentStep = direction == "increase" ? subitem.Step - 1 : subitem.Step + 1
              ChangeProcessOrder("subitem", subitem.Id, adjacentStep); // no need for async here 

              newSubitems.push({ ...subitem, Step: adjacentStep })               
            }
            else {              
              newSubitems.push(subitem)
            }        
          }

          newItems.push({ ...item, Subitems: newSubitems })          
        }        
        else {
          newItems.push(item)
        } 
      }           
      var test = activeProcess;
      activeProcess.Items = newItems
      setItemsHelper(test); 
      setItems(newItems);
    }
    else {

      let newStep = direction == "increase" ? step + 1 : step - 1
      let newItems = items.map((item: any) => {
        if (item.Step == newStep) {
          if (direction == "increase") {
            ChangeProcessOrder("item", item.Id, item.Step - 1);
            return {
              ...item,
              Step: item.Step - 1
            }
          }
          else {
            ChangeProcessOrder("item", item.Id, item.Step + 1);
            return {
              ...item,
              Step: item.Step + 1
            }
          }
        }  
        else if (item.Step == step) {
          ChangeProcessOrder("item", item.Id, newStep);
          return {
            ...item,
            Step: newStep
          }
        }   
        else {
          return item
        }
      });    
      var test = activeProcess;
      activeProcess.Items = newItems
      setItemsHelper(test);      
      setItems(newItems)
    }
  }

  const deleteItem = async (id: number, type: string) => {    
    await DeleteProcessItem(id, type)
    .then((res: any) => {
      if (res.status == 200) {
        if (type == "subitem") {
          let newItems = items.map((item) => {
            return {
              ...item,
              Subitems: item.Subitems.filter((subitem: any) => subitem.Id !== id)
            }
          })
          setItems(newItems);
          var test = activeProcess;
          activeProcess.Items = newItems
          setItemsHelper(test);
          setDeleteDialog(-1);
        }
        else if (type == "note") {          

          let newItems = items.map((item) => {
            let subitems = item.Subitems.map((subitem: any) => {
              return {
                ...subitem,
                Notes: subitem.Notes.filter((note: any) => note.Id !== id)
              }
            })

            return {
              ...item,
              Subitems: subitems
            }
          })

          setItems(newItems);
          var test = activeProcess;
          activeProcess.Items = newItems;
          setItemsHelper(test)
        }
        else if (type == "item") {
          
          let newItems = items.filter((item: any) => item.Id !== id);          
          setItems(newItems);
          var test = activeProcess;
          activeProcess.Items = newItems
          setItemsHelper(test);
          setDeleteDialog(-1);
        }
      }
    })
  }

  const setItemsFilesHelper = (id: number, parentId: number, files: Array<any>) => {    
    let newItems = items.map((item: any) => {
      if (item.Id == parentId) {
        let subitems = item.Subitems.map((subitem: any) => {
          if (subitem.Id == id) {
            return {
              ...subitem,
              Files: files
            }
          }
          else {
            return subitem
          }
        })

        return {
          ...item,
          Subitems: subitems
        }
      }
      else {
        return item
      }
    });
        
    var test = activeProcess;
    activeProcess.Items = newItems;
    setItems(newItems);
    setItemsHelper(test);
  }

  const resetItems = (newNote: any) => { // after adding note    
    let newItems = items.map((item: any) => {
      let subitems = item.Subitems.map((subitem: any) => {
        if (subitem.Id == newNote.SubitemId) {
          return {
            ...subitem,
            Notes: [...subitem.Notes, newNote]
          }
        }
        else {
          return subitem
        }
      })

      return {
        ...item,
        Subitems: subitems
      }
    })

    setItems(newItems);
  }

  return (
    <>      
      <div className={cx(styles.table, theme.darkMode && styles.darkMode)}>
        {
          items.length > 0 ? (
            <Stepper orientation='vertical' activeStep={activeStep} style={{ marginBottom: "20px" }}>
              {
                items.sort((a: any, b: any) => a.Step - b.Step).map((item: any, index) => {                                                                    
                  return (
                    <Step        
                      completed={false}              
                      key={item.Id} 
                      disabled={true}
                      sx={{   
                        '& .MuiStepLabel-root': {
                          padding: 0,
                          cursor: "pointer",
                        },                
                        '& .MuiStepLabel-root .Mui-active': {
                          color: '#5A95F5', // circle color (ACTIVE)                                                                
                        },
                        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                          {
                            color: 'common.white', // Just text label (ACTIVE)
                          },                   
                      }}
                    >
                      <StepLabel                      
                        onClick={(e) => handleActiveStep(e, index)}
                      >
                        <div className={styles.labelBox}>                          
                          <h1 className={cx(styles.itemHeader, theme.darkMode && styles.darkMode)}>{item.Title}</h1>
                          <div className={styles.chevronBox} id="chevron-box">
                            {
                              index !== 0 && (
                                <img onClick={() => changeOrder(item.Id, null, item.Step, "decrease", "step")} className={styles.chevron} src={theme.darkMode ? ChevronWhite : Chevron} id={styles.first_chevron} />
                              )
                            }
                            {
                              index !== items.length - 1 && (
                                <img onClick={() => changeOrder(item.Id, null, item.Step, "increase", "step")} className={styles.chevron} src={theme.darkMode ? ChevronWhite : Chevron} id="chevron"/>
                              )
                            }
                          </div>
                        </div>
                        <p style={{ margin: 0, paddingLeft: "2px", color: "#B0B0B0" }}>{item.Subitems.length} item{item.Subitems.length !== 1 && "s"}</p>
                      </StepLabel>
                      <StepContent>  
                      <ul className={styles.subitemList}>
                          {
                            item.Subitems && (
                              item.Subitems.sort((a: any, b: any) => a.Step - b.Step).map((subitem: any, i: any) => {                            
                                var needArrow = true;                                                            

                                if (item.Subitems.length <= 1) needArrow = false;                                                             
                                
                                return  (
                                  <Subitem 
                                    key={subitem.Id}
                                    title={subitem.Title} 
                                    itemFiles={subitem.Files} 
                                    itemId={item.Id} 
                                    id={subitem.Id} 
                                    deleteSubitem={deleteItem} 
                                    index={i} 
                                    subitemLength={item.Subitems.length} 
                                    changeOrder={changeOrder} 
                                    parentStep={item.Step}
                                    step={subitem.Step} 
                                    setItemFilesHelper={setItemsFilesHelper}  
                                    deleteItems={deleteItems}    
                                    notes={subitem.Notes}
                                    resetItems={resetItems}                 
                                  />
                                )
                              })
                            )
                          }      
                          <li style={{ display: 'flex', flexDirection: 'row' }}>
                            <input placeholder="New Subitem" type="text" className={styles.newSubItemInput} id={`new-subitem-input-${item.Id}`} />
                            <button onClick={() => createNewSubitem(item)} className={styles.newSubItemButton} id={`new-subitem-button-${item.Id}`}>Add +</button>
                          </li>
                      </ul>       
                      <div>
                        {
                          deleteItems && (
                            <p onClick={() => setDeleteDialog(index)}className={cx(styles.itemDelete, theme.darkMode && styles.darkMode)}>Delete Step</p>
                          )
                        }
                        <Dialog
                          open={deleteDialog == index}
                          onClose={() => setDeleteDialog(-1)}
                          sx={{
                            "& .MuiPaper-root": {
                              width: "60vw",
                              padding: "30px 40px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              boxSizing: "border-box",
                              textAlign: 'center'
                            },
                          }}
                        >
                          <h1 className={styles.dialogHeader}>Delete "{item.Title}"?</h1> 
                          <p style={{ fontSize: '1.2rem', margin: "0 0 40px 0"}}>This action cannot be undone.</p>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <button className={styles.formButton} style={{ backgroundColor: "#9C0E0E" }} onClick={() => deleteItem(item.Id, "item")}>Confirm</button>
                            <button className={styles.formButton} style={{ backgroundColor: "transparent", color: '#000000', border: "solid 2px #cacaca" }} onClick={() => setDeleteDialog(-1)}>Cancel</button>
                          </div>  
                        </Dialog>
                      </div>               
                      </StepContent>                    
                    </Step>
                  )
                })
              }            
            </Stepper>
          ) : (
            <h1 style={{ margin: "-5px 0 15px 0", fontSize: '1.5rem'}}>No Items Yet</h1>
          )
        }     
        <div style={{ display: 'flex', alignItems: "center", paddingLeft: `${items.length > 0 ? "35px" : "0"}` }}>
          <input value={newStepVal} onChange={(e) => setNewStepVal(e.currentTarget.value)} placeholder='New Step' className={styles.newStepInput} />
          <button onClick={() => createNewStep()} className={styles.newStepButton}>Add +</button>
        </div>     
      </div>      
    </>
  )
}

export default ProcessTable
