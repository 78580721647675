import React, { useEffect, useState, useContext } from "react";
import styles from "./AddEditContract.module.scss";
import { ThemeContext } from "../../contexts/ThemeContext";
import { AuthContext } from "../../contexts/AuthContext";
import { GetContracts } from "../../services/authServices";
import cx from "classnames";
import { FilePlus, File, Download, Plus, X } from "react-feather";
import {
  AddContract,
  AddContractFiles,
  EditContract,
  GetEvaluationKPIs,
  DeleteContractDocuments,
} from "../../services/authServices";
import {
  budgets,
  remuneration,
  currencies,
  countries,
} from "../addBenchmarks/Info.js";
import { Dialog } from "@mui/material";
import ContractEvaluation from "./ContractEvaluation";

const AddEditContractView = () => {
  const params = new URLSearchParams(window.location.search);
  const [id, setId] = useState(params.get("id"));

  const auth = useContext(AuthContext);
  const [action, setAction] = useState(id == null ? "Add" : "Edit");
  const [contracts, setContracts] = useState([]);
  const [editContract, setEditContract] = useState({
    ContractId: null,
    Advertiser: null,
    Agency: null,
    AgencyGroup: null,
    Services: null,
    DateOfReview: null,
    PreparedByAbintus: "Yes",
    Country: "Various",
    AnnualMediaBudget: "Less than €1M",
    Score: "0",
    // Rating: null,
    AnnualNetSpend: null,
    MaxFees: null,
    RemunerationModel: "Commission",
    EstimatedAVBs: null,
    AVBCalculation: "Commitment",
    StartDate: null,
    ContractLength: "1 Year",
    AutoRenew: "Yes",
    PaymentTerms: "Less than 30 days",
    Currency: "EUR (€)",
    ContractUrl: null,
    Uploader: `${auth.user[1]} ${auth.user[2]}`,
    // AdminId: auth.user[0],
    NewFile: [],
  });
  const [errors, setErrors] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingContract, setLoadingContract] = useState(false);
  const theme = useContext(ThemeContext);
  const border = theme.darkMode ? "#065a79" : "rgb(213, 213, 213)";

  const changeAction = (e) => {
    if (e.target.value == "Edit an existing contract") {
      setAction("Edit");
    } else {
      setAction("Add");
      setEditContract({ ContractId: null, NewFile: [] });
    }
  };

  const updateId = (e) => {
    setId(e.target.value);
  };

  const getContracts = async () => {
    var test = [];
    await GetContracts().then((res) => {
      test = [...res];
    });
    setContracts(test);
  };

  useEffect(() => {
    getContracts();
  }, []);

  useEffect(() => {
    if (id != null) {
      queryContracts();
    }
  }, [id, contracts]);

  useEffect(() => {
    console.log(editContract);
  }, [editContract]);

  const queryContracts = async () => {
    var number = id;
    setLoadingContract(true);

    if (contracts.length) {
      for (var i = 0; i <= contracts.length - 1; i++) {
        if (contracts[i].ContractId == number) {
          var kpis = await GetEvaluationKPIs(number);
          contracts[i]["NewFile"] = [];
          var combinedData = {
            ...contracts[i],
            ...kpis,
          };

          setEditContract(combinedData);
          setLoadingContract(false);
          break;
        } else {
          var blankState = editContract;
          for (const key in blankState) {
            blankState[key] = null;
          }
          setEditContract({
            ...blankState,
            PreparedByAbintus: "Yes",
            Country: "Various",
            AnnualMediaBudget: "Less than €1M",
            RemunerationModel: "Commission",
            AVBCalculation: "Commitment",
            ContractLength: "1 Year",
            AutoRenew: "Yes",
            PaymentTerms: "Less than 30 days",
            Currency: "EUR (€)",
            NewFile: [],
          });
          setLoadingContract(false);
        }
      }
    }
  };

  const update = (e, property) => {
    var value = e;
    if (typeof e == "object" && e.target.value !== undefined) {
      value = e.target.value;
    }

    if (value == "") {
      value = null;
    }

    if (value == "true") value = true;
    if (value == "false") value = false;

    setEditContract((prevEditContract) => ({
      ...prevEditContract,
      [property]: value,
    }));
  };

  // This is to prevent users from typing non-numeric values. I couldn't use "number" inputs because they were behaving strangely
  const updateNumericInput = (e, property) => {
    var value = e.target.value;
    var numericValue = value.replace(/[^0-9.]/g, "");
    if (numericValue == "") {
      numericValue = null;
    }

    setEditContract((prevEditContract) => ({
      ...prevEditContract,
      [property]: numericValue,
    }));
  };

  const submitContract = async (e) => {
    e.preventDefault();
    setErrors(false);

    var hasNulls = false;
    Object.entries(editContract).forEach(([key, value], i) => {
      if (
        action == "Add" &&
        !value &&
        key !== "ContractId" &&
        key !== "ContractUrl" &&
        !key.includes("Comment")
      ) {
        console.log(key);
        hasNulls = true;
      }
    });

    if (!hasNulls) {
      if (action == "Add") {
        await AddContract(editContract).then(async (res) => {
          if (editContract.NewFile.length) {
            await AddContractFiles(editContract.NewFile, res.data).then(
              (res2) => {
                console.log(res2);
              },
            );
          }
        });
        // setLoading(false);
        setConfirmDialog(true);
      } else if (action == "Edit") {
        await EditContract(editContract).then(async (res) => {
          if (res.status == 200 && editContract.NewFile.length) {
            await AddContractFiles(
              editContract.NewFile,
              editContract.ContractId,
            ).then((res) => {
              console.log(res);
            });
          }
        });
        setConfirmDialog(true);
        if (deletedFiles.length) {
          await DeleteContractDocuments(deletedFiles);
        }
        setLoading(false);
      }
    } else {
      console.log("has nulls");
      setErrors(true);
    }
  };

  const selectFile = (e) => {
    e.preventDefault();
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = (_) => {
      let files = input.files?.[0];
      setEditContract((prevEditContract) => ({
        ...prevEditContract,
        NewFile: [...editContract.NewFile, files],
      }));
    };
    input.click();
  };

  const deleteFile = (file) => {
    if (Array.isArray(file)) {
      setDeletedFiles((prev) => [...prev, file]);
      setDeleteDialog(true);
    } else if (typeof file == "object") {
      var filtered = editContract.NewFile.filter((item) => {
        return item.name != file.name;
      });
      setEditContract((prevEditContract) => ({
        ...prevEditContract,
        NewFile: filtered,
      }));
    }
  };

  const undoDeleteFile = () => {
    setDeleteDialog(false);
    setDeletedFiles((prev) => prev.slice(0, prev.length - 1));
  };

  const confirmDelete = () => {
    setDeleteDialog(false);
    var filtered = editContract.ContractUrl.filter((item) => {
      return item[4] !== deletedFiles[deletedFiles.length - 1][4];
    });
    setEditContract((prevEditContract) => ({
      ...prevEditContract,
      ContractUrl: filtered,
    }));
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const fileUpload = () => {
    if (
      !editContract.NewFile.length &&
      (editContract.ContractUrl == "N/A" || editContract.ContractUrl == null)
    ) {
      return (
        <div
          className={cx(styles.dropBox, theme.darkMode && styles.darkMode)}
          onClick={(e) => selectFile(e)}
        >
          <FilePlus style={{ height: "40px", width: "40px" }} />
          <h2>Click to upload a document</h2>
        </div>
      );
    } else if (editContract.NewFile.length && editContract.ContractUrl) {
      var combinedArray = [
        ...editContract.ContractUrl,
        ...editContract.NewFile,
      ];

      return (
        <div>
          <button
            className={cx(
              styles.addDocument,
              theme.darkMode && styles.darkMode,
            )}
            onClick={(e) => selectFile(e)}
          >
            <Plus style={{ height: "22px", width: "22px" }} /> Document
          </button>
          {combinedArray.map((item, i) => {
            var fileUrl = "";
            var fileName = "";
            var newFile = false;
            if (Array.isArray(item)) {
              fileUrl = item[1];
              fileName = item[3];
            } else {
              fileUrl = URL.createObjectURL(item);
              fileName = item.name;
              newFile = true;
            }

            return (
              <div
                key={i}
                className={cx(
                  styles.document,
                  theme.darkMode && styles.darkMode,
                )}
              >
                <File style={{ marginRight: "10px" }} />
                <p style={{ marginRight: "20px" }}>{fileName}</p>
                {newFile && (
                  <p style={{ color: "#1FA412", fontWeight: 700 }}>New</p>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    right: "5px",
                    textDecoration: "none",
                    color: theme.darkMode ? "#D0E5F2" : "#000000",
                  }}
                >
                  <X
                    onClick={() => deleteFile(item)}
                    className={styles.deleteFile}
                  />
                  <a
                    href={fileUrl}
                    style={{ color: "inherit", marginLeft: "10px" }}
                    target="_blank"
                  >
                    <Download />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (
      (!editContract.NewFile.length && editContract.ContractUrl) ||
      (editContract.NewFile.length && !editContract.ContractUrl)
    ) {
      var arr = editContract.NewFile.length
        ? editContract.NewFile
        : editContract.ContractUrl;
      return (
        <>
          <button
            className={cx(
              styles.addDocument,
              theme.darkMode && styles.darkMode,
            )}
            onClick={(e) => selectFile(e)}
          >
            <Plus style={{ height: "22px", width: "22px" }} /> Document
          </button>
          {arr.map((item, i) => {
            var fileName = editContract.NewFile.length
              ? `${item.name}`
              : item[3];
            return (
              <div
                key={i}
                className={cx(
                  styles.document,
                  theme.darkMode && styles.darkMode,
                )}
              >
                <File style={{ marginRight: "10px" }} />
                <p>{fileName}</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    right: "5px",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <X
                    onClick={() => deleteFile(item)}
                    className={styles.deleteFile}
                  />
                  <a
                    href={editContract.NewFile.length ? item : item[1]}
                    style={{ marginLeft: "10px", color: "inherit" }}
                    target="_blank"
                  >
                    <Download />
                  </a>
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };

  return (
    <div className={styles.container} id="scrollable-container">
      <div className={cx(styles.reportLoading, loading && styles.visible)}>
        <div className={styles.loadingDot}></div>
        <div className={styles.loadingDot}></div>
        <div className={styles.loadingDot}></div>
      </div>
      <Dialog
        open={confirmDialog}
        sx={{
          "& .MuiPaper-root": {
            border: "none",
            borderRadius: "8px",
            padding: "20px",
            minWidth: "400px",
            textAlign: "center",
          },
        }}
      >
        {action == "Add" ? (
          <h2>Contract successfully added</h2>
        ) : (
          <h2>Contract {editContract.ContractId} successfully edited</h2>
        )}
        <div className={styles.dialogButtons}>
          <button
            style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
            onClick={reloadPage}
          >
            Done
          </button>
        </div>
      </Dialog>
      <Dialog
        open={deleteDialog}
        sx={{
          "& .MuiPaper-root": {
            border: "none",
            borderRadius: "8px",
            padding: "20px",
            minWidth: "400px",
            textAlign: "center",
          },
        }}
      >
        <h2 style={{ marginBottom: "30px" }}>
          Delete{" "}
          {deletedFiles.length
            ? deletedFiles[deletedFiles.length - 1][3]
            : "file"}
          ?
        </h2>
        <div className={styles.dialogButtons}>
          <button
            style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
            onClick={confirmDelete}
          >
            Confirm
          </button>
          <button onClick={undoDeleteFile}>Undo</button>
        </div>
      </Dialog>
      <h1
        className={styles.header}
        style={{ color: theme.darkMode ? "#D0E5F2" : "#000000" }}
      >
        Add and Edit Contracts
      </h1>
      <div className={cx(styles.card, theme.darkMode && styles.darkMode)}>
        <form
          className={cx(
            styles.form,
            theme.darkMode && styles.darkMode,
            loadingContract && styles.loadingContract,
          )}
        >
          <div
            style={{ marginBottom: editContract.ContractId ? "10px" : "90px" }}
            className={cx(styles.formHeader, theme.darkMode && styles.darkMode)}
          >
            <div
              style={{
                marginBottom: "10px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ margin: 0 }}>What would you like to do?</h2>
              <h3
                style={{
                  margin: 0,
                  display: action == "Edit" ? "block" : "none",
                }}
              >
                Contract Id
              </h3>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <select
                className={cx(
                  styles.generalInfoSelect,
                  theme.darkMode && styles.darkMode,
                )}
                onChange={(e) => changeAction(e)}
                value={
                  action == "Add"
                    ? "Add a new contract"
                    : "Edit an existing contract"
                }
              >
                <option>Add a new contract</option>
                <option>Edit an existing contract</option>
              </select>
              <input
                value={id !== null ? id : ""}
                style={{
                  display: action == "Edit" ? "block" : "none",
                  width: "33%",
                  margin: 0,
                }}
                type="text"
                onChange={(e) => {
                  // queryContracts(e)
                  updateId(e);
                }}
              />
            </div>
          </div>
          {loadingContract ? (
            <div className={styles.loadingDotContainer}>
              <div className={styles.loadingDot}></div>
              <div className={styles.loadingDot}></div>
              <div className={styles.loadingDot}></div>
            </div>
          ) : (
            <div style={{ padding: "0 50px 50px" }}>
              {editContract.ContractId !== null && (
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "50px",
                    fontSize: "1.2rem",
                  }}
                >
                  You are now editing contract:{" "}
                  <strong>{editContract.ContractId}</strong>
                </p>
              )}
              <h2 style={{ marginBottom: "20px" }}>General Info</h2>
              <ul>
                <li className={styles.generalInfoListItem}>
                  <label>Advertiser</label>
                  <input
                    style={{
                      border: `solid 1px ${errors && !editContract.Advertiser ? "#CB0A0A" : border}`,
                    }}
                    value={
                      editContract.Advertiser ? editContract.Advertiser : ""
                    }
                    onChange={(e) => update(e, "Advertiser")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Agency</label>
                  <input
                    style={{
                      border: `solid 1px ${errors && !editContract.Agency ? "#CB0A0A" : border}`,
                    }}
                    value={editContract.Agency ? editContract.Agency : ""}
                    onChange={(e) => update(e, "Agency")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Agency Group</label>
                  <input
                    style={{
                      border: `solid 1px ${errors && !editContract.AgencyGroup ? "#CB0A0A" : border}`,
                    }}
                    type="text"
                    value={
                      editContract.AgencyGroup ? editContract.AgencyGroup : ""
                    }
                    onChange={(e) => update(e, "AgencyGroup")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Services</label>
                  <input
                    style={{
                      border: `solid 1px ${errors && !editContract.Services ? "#CB0A0A" : border}`,
                    }}
                    type="text"
                    value={editContract.Services ? editContract.Services : ""}
                    onChange={(e) => update(e, "Services")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Date of Review</label>
                  <input
                    type="date"
                    style={{
                      border: `solid 1px ${errors && !editContract.DateOfReview ? "#CB0A0A" : border}`,
                    }}
                    value={
                      editContract.DateOfReview ? editContract.DateOfReview : ""
                    }
                    onChange={(e) => update(e, "DateOfReview")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Prepared By Abintus</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => update(e, "PreparedByAbintus")}
                    value={
                      editContract.PreparedByAbintus
                        ? editContract.PreparedByAbintus
                        : "Yes"
                    }
                  >
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Country</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => update(e, "Country")}
                    value={
                      editContract.Country ? editContract.Country : countries[0]
                    }
                  >
                    {countries.map((item) => {
                      return <option key={item}>{item}</option>;
                    })}
                  </select>
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Annual Media Budget (€)</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => update(e, "AnnualMediaBudget")}
                    value={
                      editContract.AnnualMediaBudget
                        ? editContract.AnnualMediaBudget
                        : budgets[0]
                    }
                  >
                    {budgets.map((item) => {
                      return <option key={item}>{item}</option>;
                    })}
                  </select>
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Annual Net Spend (€)</label>
                  <input
                    type="text"
                    step="any"
                    style={{
                      border: `solid 1px ${errors && !editContract.AnnualNetSpend ? "#CB0A0A" : border}`,
                    }}
                    value={
                      editContract.AnnualNetSpend
                        ? editContract.AnnualNetSpend
                        : ""
                    }
                    onChange={(e) => updateNumericInput(e, "AnnualNetSpend")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Max Fees (€)</label>
                  <input
                    type="text"
                    step="any"
                    style={{
                      border: `solid 1px ${errors && !editContract.MaxFees ? "#CB0A0A" : border}`,
                    }}
                    value={editContract.MaxFees ? editContract.MaxFees : ""}
                    onChange={(e) => updateNumericInput(e, "MaxFees")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Remuneration Model</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => update(e, "RemunerationModel")}
                    value={
                      editContract.RemunerationModel
                        ? editContract.RemunerationModel
                        : remuneration[0]
                    }
                  >
                    {remuneration.map((item) => {
                      return <option key={item}>{item}</option>;
                    })}
                  </select>
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Estimated AVBs</label>
                  <input
                    type="text"
                    step="any"
                    style={{
                      border: `solid 1px ${errors && !editContract.EstimatedAVBs ? "#CB0A0A" : border}`,
                    }}
                    value={
                      editContract.EstimatedAVBs
                        ? editContract.EstimatedAVBs
                        : ""
                    }
                    onChange={(e) => updateNumericInput(e, "EstimatedAVBs")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>AVB Calculation</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => update(e, "AVBCalculation")}
                    value={
                      editContract.AVBCalculation
                        ? editContract.AVBCalculation
                        : "Commitment"
                    }
                  >
                    <option>Commitment</option>
                    <option>Fair Share</option>
                    <option>Full Transparency</option>
                    <option>None</option>
                    <option>Not Applicable</option>
                    <option>Not Defined</option>
                  </select>
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Start Date</label>
                  <input
                    type="date"
                    value={editContract.StartDate ? editContract.StartDate : ""}
                    onChange={(e) => update(e, "StartDate")}
                  />
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Length of Contract</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => update(e, "ContractLength")}
                    value={
                      editContract.ContractLength
                        ? editContract.ContractLength
                        : "1 Year"
                    }
                  >
                    <option>1 Year</option>
                    <option>2 Years</option>
                    <option>3 Years</option>
                    <option>4 Years</option>
                    <option>5 Years</option>
                    <option>Indefinite</option>
                  </select>
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Auto Renew</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => update(e, "AutoRenew")}
                    value={
                      editContract.AutoRenew ? editContract.AutoRenew : "Yes"
                    }
                  >
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Payment Terms</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => update(e, "PaymentTerms")}
                    value={
                      editContract.PaymentTerms
                        ? editContract.PaymentTerms
                        : "Less than 30 days"
                    }
                  >
                    <option>Less than 30 days</option>
                    <option>30 days</option>
                    <option>60 days</option>
                    <option>90 days</option>
                    <option>120 days</option>
                  </select>
                </li>
                <li className={styles.generalInfoListItem}>
                  <label>Currency</label>
                  <select
                    className={cx(
                      styles.generalInfoSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    value={
                      editContract.Currency
                        ? editContract.Currency
                        : currencies[0]
                    }
                    onChange={(e) => update(e, "Currency")}
                  >
                    {currencies.map((item) => {
                      return <option key={item}>{item}</option>;
                    })}
                  </select>
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "100%",
                    paddingTop: "40px",
                    marginTop: "20px",
                    marginBottom: "50px",
                    position: "relative",
                  }}
                >
                  {fileUpload()}
                </li>
                <li
                  className={styles.generalInfoListItem}
                  style={{ width: "100%" }}
                >
                  <h2 style={{ marginBottom: "20px" }}>Contract Evaluation</h2>
                </li>
                <ContractEvaluation
                  contract={editContract}
                  updateValues={update}
                />
                <button
                  type="submit"
                  onClick={(e) => submitContract(e)}
                  className={cx(
                    styles.submitButton,
                    theme.darkMode && styles.darkMode,
                  )}
                >
                  {action == "Add" ? "Add new contract" : "Update contract"}
                </button>
              </ul>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddEditContractView;
