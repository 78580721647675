import React, { useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { EmployeeContext } from "../contexts/EmployeeContext";
var jwt = require("jsonwebtoken");

interface AdminRoutesProps {
  children: any;  
  allowedPages: any;
  page: string
}


const AdminRoute = (props: AdminRoutesProps) => {  
  const user = useContext(AuthContext).user;
  const employees = useContext(EmployeeContext).employees;
  
  var raw = localStorage.getItem("authorization");

  if (raw !== null) {
    var auth = jwt.decode(raw);    
  }
  
  if (props.allowedPages[props.page] || user[7] == 2) {    
    return props.children;
  }
  else {
    return <Navigate to="/dashboard" />
  }
  
};

export default AdminRoute;
