import React, { useContext, useEffect, useState, useRef } from "react";
import {
  GetSpecificChart,
  GetChartProjects,
  GetAllUsers,
  AddProject,
  GetProjectSubitems,
  AddProjectSubitem,
  DeleteProject,
  GetComments,
  UpdateProject,
  UpdateProjectSubitem
} from "../../services/authServices";
import { useParams } from "react-router-dom";
import { ThemeContext } from "../../contexts/ThemeContext";
import styles from "./TaskTable.module.scss";
import cx from "classnames";
import { todaysDate } from "../../common/helpers/todaysDate";
import AnonPhoto from "../../assets/media/anon.jpeg";
import { Plus } from "react-feather";
import { ChevronRight, X, Search, User, Shuffle } from "react-feather";
import { Dialog, Menu } from "@mui/material";
import TaskTable from "../../common/components/TaskTable/TaskTable";
import WhiteTrash from "../../assets/media/white-trash.png";
import BlackTrash from "../../assets/media/trash-black.png";
import WhiteCopy from "../../assets/media/copy-white.png";
import BlackCopy from "../../assets/media/copy-black.png";

interface Chart {
  id: number,
  title: string;
  author: string;
  dateCreated: string;
}

interface Project {
  id: number | null;
  chartId: string | undefined;
  orderId: number;
  title: string;
  sponsor: string;
  sponsorPhoto: string | null;
  manager: string;
  managerPhoto: string | null;
  priority: string;
  status: string;
  keyClient: string;
  startDate: string;
  category: string | undefined | null;
  subItems: Subitem[];
  comments: Comment[] | null;
}

interface Comment {
  projectId: number;
  comment: string;
  commenter: string;
  commenterPhoto: string;
  date: string;
}

interface Subitem {
  id: any;
  chartId: string | undefined;
  projectId: number;
  title: string;
  owner: string | null;
  ownerPhoto: string | null;
  day1: string | null;
  day2: string | null;
  day3: string | null;
  day4: string | null;
  day5: string | null;
  orderId: number;
}

interface User {
  id: Number;
  name: string;
  email: string;
  photo: string;
  role: number;
}

const TaskTableView = () => {
  const [chart, setChart] = useState<Chart>();
  const [unfilteredProjects, setUnfilteredProjects] = useState<Project[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [subitems, setSubitems] = useState<Subitem[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [newProjectLoading, setNewProjectLoading] = useState(false);
  const [newSubitemLoading, setNewSubItemLoading] = useState<boolean>(false);
  // const [expandedProjects, setExpandedProjects] = useState<number[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [selectedSubitems, setSelectedSubitems] = useState<number[]>([]);
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const [titleFilter, setTitleFilter] = useState("");
  const [userFilter, setUserFilter] = useState<User | null>(null);
  const [userFilterDisplay, setUserFilterDisplay] = useState(false);
  const [sortFilters, setSortFilters] = useState({
    type: "",
    value: "",
  });
  const [sortMenuDisplay, setSortMenuDisplay] = useState(false);
  const [mouseInSortMenu, setMouseInSortMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subitemDrag, setSubitemDrag] = useState(false);
  const [drag, setDrag] = useState<number | null>(null);
  const [dragOver, setDragOver] = useState<number | null>(null);
  const [movedElement, setMovedElement] = useState<Project | Subitem | null>(null);
  const [anchorElNewProj, setAnchorElNewProj] = useState(null);
  const [tableDragOver, setTableDragOver] = useState<string | null>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  var params = useParams();
  const theme = useContext(ThemeContext);

  const getChart = async (filter: any = null) => {    
    setProjects([]);
    setSubitems([]);
    if (params.chartId != undefined) {
      var result: any = await GetSpecificChart(parseInt(params.chartId));
      setChart({
        id: result[0][0],
        title: result[0][1],
        author: result[0][2],
        dateCreated: result[0][3],
      });

      var comments: Comment[] = [];
      var commentRes: any = await GetComments(params.chartId, "dbo.ProjectComments");      
      commentRes.forEach((comment: any) => {
        comments.push({
          projectId: comment[2],
          comment: comment[6],
          commenter: comment[3],
          commenterPhoto: comment[4],
          date: comment[5],
        });
      });

      var newSubitems: Subitem[] = [];
      var subitems: any = await GetProjectSubitems(parseInt(params.chartId));         
      
      subitems.forEach((subitem: any) => {        
        newSubitems.push({
          id: subitem[0],
          chartId: subitem[1],
          projectId: subitem[2],
          title: subitem[3],
          owner: subitem[4],
          ownerPhoto: subitem[5],
          day1: subitem[6],
          day2: subitem[7],
          day3: subitem[8],
          day4: subitem[9],
          day5: subitem[10],
          orderId: subitem[11]
        });
      });           
      setSubitems(newSubitems);

      var newProjects: Project[] = [];
      var projects: any = await GetChartProjects(parseInt(params.chartId));      
      projects.forEach((project: any) => {        
        newProjects.push({
          id: project[0],
          chartId: project[1],
          orderId: project[11],
          title: project[2],
          sponsor: project[3],
          sponsorPhoto: project[9],
          manager: project[4],
          managerPhoto: project[10],
          priority: project[5],
          status: project[6],
          keyClient: project[7],
          startDate: project[8],
          category: project[12],
          subItems: newSubitems.filter((item) => item.projectId == project[0]),
          comments: comments.filter((item) => item.projectId == project[0]),
        });
      });

      var sortedProjects = newProjects.sort(
        (a: any, b: any) => a.orderId - b.orderId,
      );

      // if (!expandedProjects.length) {        
      //   setExpandedProjects([]);
      // }

      // const initialSelectedProjects = newProjects.map(() => false);      
      setUnfilteredProjects(sortedProjects);
      setProjects(sortedProjects);
      // setSelectedProjects(initialSelectedProjects);
    }

    var users: any = await GetAllUsers();
    setUsers(users);
  };  

  const newProject = async (cat: any = null) => {
    var category = cat !== null ? cat : selectRef.current?.value;
    setAnchorElNewProj(null);

    setNewProjectLoading(true);    
    const newProjectItem: Project = {
      id: null,
      chartId: params.chartId,
      orderId: projects.length ? projects[projects.length - 1].orderId + 1 : 0,
      title: "",
      sponsor: "",
      sponsorPhoto: AnonPhoto,
      manager: "",
      managerPhoto: AnonPhoto,
      priority: "",
      status: "",
      keyClient: "",
      startDate: new Date().toISOString().split('T')[0],
      subItems: [],
      comments: null,
      category: category,
    };

    await AddProject(newProjectItem).then((res: any) => {
      newProjectItem["id"] = res.data.id;
      setProjects((prevProjects) => [...prevProjects, newProjectItem]);
      setNewProjectLoading(false);
    });
  };

  const newSubitem = async (title: string, projectId: number) => {
    var project = projects.find(item => item.id == projectId);    
    var orderIds = project?.subItems.map(item => item.orderId).sort((a: any, b: any) => a.orderId - b.orderId);
    var newOrderId = null;
    if (orderIds == undefined || orderIds.length == 0) {
      newOrderId = 0;
    }
    else {
      newOrderId = orderIds[orderIds.length - 1]
    }
        
    const newProjectSubItem: Subitem = {
      id: null,
      chartId: params.chartId,
      projectId: projectId,
      title: title,
      owner: '',
      ownerPhoto: "",
      day1: "",
      day2: "",
      day3: "",
      day4: "",
      day5: "",
      orderId: newOrderId
    };

    setSubitems([...subitems, newProjectSubItem]);

    await AddProjectSubitem(newProjectSubItem).then((res: any) => {
      newProjectSubItem.id = res.data.id;
      let updatedProjects = [];
      for (var i = 0; i <= projects.length - 1; i++) {
        let current = projects[i];
        if (current.id == projectId) {
          current.subItems.push(newProjectSubItem);
        }
        updatedProjects.push(current);
      }

      setProjects(updatedProjects);
    });
  };

  const selectProject = (e: any, id: number) => {
    var newArr = [...selectedProjects];
    if (!newArr.includes(id)) newArr.push(id)
    else newArr = selectedProjects.filter(x => x !== id);

    if (selectedSubitems.length == 0) setSelectedProjects(newArr)
  };

  const selectSubitem = (id: number) => {
    var newArr = [...selectedSubitems];    
    if (!newArr.includes(id)) {
      newArr.push(id);
    } else {
      newArr = selectedSubitems.filter((x) => x !== id);
    }
    if (selectedProjects.length == 0) setSelectedSubitems(newArr);
  };

  const closeMenu = () => {
    const newArr: any = [];       
    setSelectedProjects(newArr);
    setSelectedSubitems([]);
  };  

  const deleteProject = async () => {
    
    if (selectedProjects.length) {
      var newProjects = unfilteredProjects.filter((x: any) => !selectedProjects.includes(x.id));
      setUnfilteredProjects(newProjects);
      setProjects(newProjects);   
      setDeleteItemDialog(false); 
      setSelectedProjects([]);
      await DeleteProject(selectedProjects, "project");
    }
    
    if (selectedSubitems.length) {      
      var newSubitems = subitems.filter((x: any) => !selectedSubitems.includes(x.id));
      setSubitems(newSubitems);      

      var newProjects: Project[] = unfilteredProjects.map(project => {
        return {
          ...project,
          subItems: newSubitems.filter(x => x.projectId == project.id)
        }
      })      

      setUnfilteredProjects(newProjects);
      setProjects(newProjects)

      setSelectedSubitems([])
      setDeleteItemDialog(false);
      await DeleteProject(selectedSubitems, "subitem");
    }    
    
  };

  useEffect(() => {
    if (titleFilter.trim() != "") {
      var newArray: Project[] = unfilteredProjects.filter((proj) => {
        return proj.title.toUpperCase().includes(titleFilter.toUpperCase());
      });
      setProjects([...newArray]);
    } else {
      if (userFilter)
        setProjects(
          projects.filter(
            (proj) =>
              userFilter.name == proj.manager ||
              userFilter.name == proj.sponsor,
          ),
        );
      else setProjects(unfilteredProjects);
    }
  }, [titleFilter]);

  const handleMenu = (e: any, type: string) => {
    if (anchorEl == null) {
      setAnchorEl(e.currentTarget);
      if (type == "user") setUserFilterDisplay(true);
      else if (type == "sort") setSortMenuDisplay(true);
    } else {
      setUserFilterDisplay(false);
      if (!mouseInSortMenu) {
        setSortMenuDisplay(false);
        setAnchorEl(null);
      }
    }
  };

  const handleUserFilter = (user: any) => {
    if (userFilter == null) setUserFilter(user);
    else setUserFilter(null);
  };

  useEffect(() => {    
    if (userFilter) {
      setProjects(
        projects.filter(
          (proj) =>            
            proj.manager.includes(userFilter.name) || proj.sponsor.includes(userFilter.name),
        ),
      );
    } else {
      var test: Project[] = unfilteredProjects.filter((proj) => {
        return proj.title.toUpperCase().includes(titleFilter.toUpperCase());
      });
      setProjects(test);
    }
  }, [userFilter]);

  useEffect(() => {
    if (sortFilters.type == "Priority") {
      let order = ["Critical", "High", "Medium", "Low", "Always On", ""];
      var sortedArr;
      if (sortFilters.value == "DESC")
        sortedArr = projects.sort(
          (a, b) => order.indexOf(a.priority) - order.indexOf(b.priority),
        );
      else
        sortedArr = projects.sort(
          (a, b) => order.indexOf(b.priority) - order.indexOf(a.priority),
        );
      setProjects([...sortedArr]);
    }
    if (sortFilters.type == "Status") {
      let order = [
        "Ready to Present",
        "Done",
        "Working On It",
        "Awaiting Feedback",
        "Paused",
        "Stuck",
        "Not Started",
        "",
      ];
      let sortedArr;
      if (sortFilters.value == "DESC")
        sortedArr = projects.sort(
          (a, b) => order.indexOf(a.status) - order.indexOf(b.status),
        );
      else
        sortedArr = projects.sort(
          (a, b) => order.indexOf(b.status) - order.indexOf(a.status),
        );
      setProjects([...sortedArr]);
    }

    if (sortFilters.type == "Date") {
      if (sortFilters.value == "Desc")
        sortedArr = projects.sort(
          (a, b) => Date.parse(a.startDate) - Date.parse(b.startDate),
        );
      else
        sortedArr = projects.sort(
          (a, b) => Date.parse(b.startDate) - Date.parse(a.startDate),
        );
    }
  }, [sortFilters]);

  const handleDrag = (id: number | null, subItem: boolean = false) => {    
    if (subItem) setSubitemDrag(true)
    setDrag(id);    
  };

  const handleDragOver = (id: number | null, index: number) => {      
    setDragOver(id);    
  };

  const handleDragDrop = (index: number) => {
    if (subitemDrag) {
      var adjacentSubitem = { orderId: -1 }
      if (dragOver !== -1) {
        adjacentSubitem = subitems.filter((x) => x.id == dragOver)[0];      
      }
      var newSubitems = subitems.map(item => {
        if (dragOver) {          
          if (item.id == drag) {
            var newSubitem = item;

            newSubitem.orderId = adjacentSubitem.orderId + 1;                    

            UpdateProjectSubitem(item.id, item.projectId, "OrderId", newSubitem.orderId);

            return newSubitem
          }
          else if (item.orderId > adjacentSubitem.orderId) {
            let newSubitem = item;

            newSubitem.orderId = item.orderId + 1;

            UpdateProjectSubitem(item.id, item.projectId, "OrderId", newSubitem.orderId);

            return newSubitem;
          }
          else {
            return item;
          }
        }
        else {
          return item;
        }
      })

      var newProjs: any = projects.map((proj: Project) => {
        var newProject = proj;
        newProject.subItems = newSubitems.filter(item => item.projectId == proj.id);

        return newProject
      })      

      setMovedElement(newSubitems.filter((item: any) => item.orderId == drag)[0]);      
      setProjects(newProjs);
      setDrag(null);
      setDragOver(null);
      
    } else {
      var movedProject = projects.filter((x) => x.id == drag)[0];
      var adjacentProject = projects.filter((x) => x.id == dragOver)[0];
  
      var newProjects: any = projects.map((proj) => {      
        if (dragOver) {
          if (proj.orderId == movedProject.orderId) {
            UpdateProject(drag, chart!.id, "OrderId", (adjacentProject.orderId + 1).toString());
  
            var newProj = proj;
            newProj.orderId = adjacentProject.orderId + 1;
            newProj.category = tableDragOver;                    
            
            return newProj;
          } 
          else if (proj.orderId > adjacentProject.orderId) {   
            let newProj = proj;
            newProj.orderId = proj.orderId + 1;
            
            return newProj          
          } 
          else {
            return proj;
          }
        } else {
          return proj;
        }
      });
  
      var sortedProjects = newProjects.sort(
        (a: any, b: any) => a.orderId - b.orderId,
      );
      setMovedElement(
        sortedProjects.filter((x: any) => x.id == movedProject.id)[0],
      );
      setProjects(sortedProjects);
      setDrag(null);
      setDragOver(null);
      if (chart && tableDragOver) {      
        UpdateProject(drag, chart.id, "Category", tableDragOver);
      }    
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setMovedElement(null);
    }, 1500);
  }, [movedElement]);

  useEffect(() => {
    getChart();
  }, []);

  const deselectSubitems = () => {    
    setSelectedSubitems([]);
  }

  const handleNewProjMenu = (e: any) => {
    if (anchorElNewProj == null) {
      setAnchorElNewProj(e.currentTarget);
    }
    else {
      setAnchorElNewProj(null)
    }
  }

  const updateSubitem = () => {

  }

  const updateUsers = (users: string, id: number, column: string, subitemId: number | null = null) => {
    var newProjects = [];
    
    if (subitemId) {
      var newSubitems = subitems.map((item: any) => {
        if (item.id == subitemId) {
          return {
            ...item,
            owner: users
          }
        }
        else {
          return item
        }
      })

      setSubitems(newSubitems)

      newProjects = projects.map((proj: any) => {
        return {
          ...proj,
          subitems: newSubitems.filter((subitem: any) => proj.id == subitem.projectId)
        }
      })       
    }
    else {
      newProjects = projects.map((proj: any) => {
        if (proj.id == id) {
          return {
            ...proj,
            [column.toLocaleLowerCase()]: users
          }
        }
        else {
          return proj
        }
      });
    }

    setProjects(newProjects);
    setUnfilteredProjects(newProjects)
  }

  const updateStatus = (id: any, column: any, status: any, subitemId: number | null) => {    
    var newProjects = [];    
    if (subitemId != null) {
      var newSubitems = subitems.map((item) => {
        if (subitemId == item.id) {
          return {
            ...item,
            [column.toLocaleLowerCase()]: status
          }
        }
        else {
          return item;
        }
      })

      setSubitems(newSubitems);

      newProjects = projects.map((proj: any) => {
        if (proj.id == id) {
          proj.subitems = proj.subItems.map((subItem: any) => {
            if (subItem.id == subitemId) {                
              return {
                ...subItem,
                [column.toLocaleLowerCase()]: status
              }
            }
            else {
              return subItem
            }
          })
          return {
            ...proj,            
          }
        }
        else {
          return proj
        }
      }) 
    }
    else {
      newProjects = projects.map((proj: any) => {
        if (proj.id == id) {
          return {
            ...proj,
            [column.toLocaleLowerCase()]: status
          }
        }
        else {
          return proj
        }
      })
    }

    setProjects(newProjects)
    setUnfilteredProjects(newProjects)
  }  

  const updateTextValue = (id: any, column: string, value: string, subitemId: number | null) => {    
    var newProjects = [];
    if (subitemId != null) {   
      var newSubitems = subitems.map((item: any) => {
        if (item.id == subitemId) {
          return {
            ...item,
            title: value
          }          
        }
        else {
          return item
        }
      })
      
      newProjects = projects.map((proj: any) => {
        return {
          ...proj,
          subitems: newSubitems.filter((subitem: any) => proj.id == subitem.projectId)
        }
      })

      setSubitems(newSubitems)      
    }
    else {
      newProjects = projects.map((proj: any) => {
        if (proj.id == id) {
          return {
            ...proj,
            [column]: value
          }
        }
        else {
          return proj
        }
      })
    }

    setProjects(newProjects);
    setUnfilteredProjects(newProjects)
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)} id="scrollable-container">
      <div
        className={styles.titleInfo}
        style={{
          position: "sticky",
          top: 0,
          marginBottom: "40px",
          zIndex: 1200,
          backgroundColor: theme.darkMode ? "#252423" : "#F4F4F4",
        }}
      >
        <div className={styles.titleContainer} style={{ marginBottom: "20px" }}>
          <h1 className={styles.titleHeader}>{chart?.title}</h1>
          <p style={{ margin: 0, fontSize: "0.85rem" }}>
            Created by {chart?.author}
          </p>
        </div>
        <div className={styles.taskBar}>
          <button
            onClick={(e) => handleNewProjMenu(e)}
            className={cx(
              styles.newProjButton,
              theme.darkMode && styles.darkMode,
            )}
          >
            <Plus style={{ marginRight: "1px", height: "20px" }} />
            New Project
          </button>
          <Menu
            open={anchorElNewProj != null}
            anchorEl={anchorElNewProj}
            onClose={(e) => handleNewProjMenu(e)}
            sx={{
              "& .MuiPaper-root": {
                height: "50px",
                width: "250px",                                      
                backgroundColor: "#0fc65f",   
                color: "#FFFFFF",
                padding: "0 10px 20px 10px" 
              },
            }}
          >
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
              <div>
                <p style={{ margin: "0 0 5px 0", fontSize: "0.9rem" }}>Category</p>
                <select className={styles.selectMenu} ref={selectRef}>
                  <option>Current</option>
                  <option>Upcoming</option>
                  <option>Completed</option>                  
                </select>
              </div>
              <button onClick={() => newProject()} className={styles.addButton}>Create</button>
        </div>
          </Menu>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <Search
              style={{ height: "20px", width: "20px", marginRight: "10px" }}
            />
            <input
              onChange={(e) => setTitleFilter(e.currentTarget.value)}
              type="text"
              placeholder="Search"
              className={cx(
                styles.searchInput,
                theme.darkMode && styles.darkMode,
              )}
            />
          </div>
          <div
            onClick={(e) => handleMenu(e, "user")}
            className={styles.filterBox}
          >
            {userFilter ? (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => handleUserFilter(userFilter)}
              >
                <img
                  src={userFilter.photo}
                  style={{
                    objectFit: "cover",
                    height: "30px",
                    width: "30px",
                    marginRight: "10px",
                    borderRadius: "50px",
                  }}
                />
                <p style={{ fontSize: "0.8rem", margin: "0 5px 0 0" }}>
                  {userFilter.name}
                </p>
                <X style={{ height: "20px", width: "20px" }} />
              </div>
            ) : (
              <>
                <User
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
                <p style={{ margin: 0, fontSize: "0.9rem" }}>Person</p>
              </>
            )}
            <Menu
              open={userFilterDisplay}
              onClose={(e) => handleMenu(e, "user")}
              anchorEl={anchorEl}
              sx={{
                padding: 0,

                "& .MuiPaper-root": {
                  maxHeight: "150px",
                  minWidth: "100px",
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 20px",
                  zIndex: 1500,
                },
              }}
            >
              <p style={{ margin: 0 }}>Filter by user</p>
              {users.map((user) => {
                return (
                  <img
                    key={user.name}
                    onClick={() => handleUserFilter(user)}
                    src={user.photo}
                    className={styles.userFilterPhoto}
                  />
                );
              })}
            </Menu>
          </div>
          <div
            onClick={(e) => handleMenu(e, "sort")}
            className={styles.filterBox}
          >
            <Shuffle
              style={{ height: "20px", width: "20px", marginRight: "10px" }}
            />
            <p style={{ margin: 0, fontSize: "0.9rem" }}>Sort</p>
            <Menu
              open={sortMenuDisplay}
              onClose={(e) => handleMenu(e, "sort")}
              anchorEl={anchorEl}
              sx={{
                padding: 0,

                "& .MuiPaper-root": {
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  padding: "10px 20px 10px",
                  zIndex: 1000,
                },
              }}
            >
              <div
                onMouseEnter={() => setMouseInSortMenu(true)}
                onMouseLeave={() => setMouseInSortMenu(false)}
              >
                <p style={{ margin: "0 0 20px 0" }}>Sort By</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <p style={{ margin: "0", width: "70px" }}>Priority</p>
                    <select
                      defaultValue={"Select"}
                      style={{ backgroundColor: '#cacaca' }}
                      onChange={(e) =>
                        setSortFilters({
                          type: "Priority",
                          value:
                            e.currentTarget.value == "High to Low"
                              ? "DESC"
                              : "ASC",
                        })
                      }
                      className={styles.selectMenu}
                    >
                      <option disabled>Select</option>
                      <option>High to Low</option>
                      <option>Low to High</option>
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <p style={{ margin: "0", width: "70px" }}>Status</p>
                    <select
                      defaultValue={"Select"}
                      className={styles.selectMenu}
                      style={{ backgroundColor: '#cacaca' }}
                      onChange={(e) =>
                        setSortFilters({
                          type: "Status",
                          value:
                            e.currentTarget.value == "Finished to Unfinished"
                              ? "DESC"
                              : "ASC",
                        })
                      }
                    >
                      <option disabled>Select</option>
                      <option>Finished to Unfinished</option>
                      <option>Unfinished to Finished</option>
                    </select>
                  </div>
                </div>
              </div>
            </Menu>
          </div>
        </div>
      </div>
      <div onDragOver={() => setTableDragOver("Current")}>      
        <TaskTable
          status={"Current"}
          projects={projects.filter((proj) => proj.category == "Current")}
          updateUsers={updateUsers}
          updateSubitem={updateSubitem}
          updateStatus={updateStatus}
          params={params}
          users={users}
          newSubitem={newSubitem}
          newProject={newProject}
          selectedProjects={selectedProjects}
          selectedSubitems={selectedSubitems}
          selectProject={selectProject}
          selectSubitem={selectSubitem}
          deselectSubitems={deselectSubitems}
          subitemDrag={subitemDrag}
          drag={drag}
          dragOver={dragOver}
          handleDrag={handleDrag}
          handleDragOver={handleDragOver}
          handleDragDrop={handleDragDrop}
          movedElement={movedElement}      
          updateTextValue={updateTextValue}
          getChart={getChart}
        />
      </div>
      <div onDragOver={() => setTableDragOver("Upcoming")}>
        <TaskTable
          status={"Upcoming"}
          projects={projects.filter((proj) => proj.category == "Upcoming")}
          updateUsers={updateUsers}
          updateSubitem={updateSubitem}
          updateStatus={updateStatus}
          params={params}
          users={users}
          newSubitem={newSubitem}
          newProject={newProject}
          selectedProjects={selectedProjects}
          selectedSubitems={selectedSubitems}
          selectProject={selectProject}
          selectSubitem={selectSubitem}
          deselectSubitems={deselectSubitems}
          subitemDrag={subitemDrag}
          drag={drag}
          dragOver={dragOver}
          handleDrag={handleDrag}
          handleDragOver={handleDragOver}
          handleDragDrop={handleDragDrop}
          movedElement={movedElement}
          updateTextValue={updateTextValue}
          getChart={getChart}
        />
      </div> 
      <div onDragOver={() => setTableDragOver("Completed")}>
        <TaskTable
          status={"Completed"}
          projects={projects.filter((proj) => proj.category == "Completed")}
          updateUsers={updateUsers}
          updateSubitem={updateSubitem}
          updateStatus={updateStatus}
          params={params}
          users={users}
          newSubitem={newSubitem}
          newProject={newProject}
          selectedProjects={selectedProjects}
          selectedSubitems={selectedSubitems}
          selectProject={selectProject}
          selectSubitem={selectSubitem}
          deselectSubitems={deselectSubitems}
          subitemDrag={subitemDrag}
          drag={drag}
          dragOver={dragOver}
          handleDrag={handleDrag}
          handleDragOver={handleDragOver}
          handleDragDrop={handleDragDrop}
          movedElement={movedElement}
          updateTextValue={updateTextValue}
          getChart={getChart}
        />    
      </div>  
      <Dialog
        open={deleteItemDialog}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.darkMode ? "#323130" : "#FFFFFF",
            color: theme.darkMode ? "#FFFFFF" : "#000000",
            minHeight: "400px",
            padding: "30px 0 40px 0",
            boxSizing: "border-box",
            width: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2 style={{ margin: 0, fontFamily: "PoppinsBold" }}>
            Are you sure?
          </h2>
          <p
            style={{
              margin: "0 0 30px 0",
              fontWeight: 200,
              fontFamily: "PoppinsLight",
              fontSize: "1.1rem",
            }}
          >
            The following projects will be deleted:{" "}
          </p>
          {
            selectedProjects.length > 0 ? (
              projects.map((item, index) => {
                if (item.id && selectedProjects.includes(item.id)) {
                  return (
                    <p
                      key={item.title}
                      style={{
                        fontFamily: "Arial",
                        margin: "5px",
                        fontSize: "1.2rem",
                      }}
                    >
                      {item.title}
                    </p>
                  );
                }
              })
            ) : (
              subitems.map((item, index) => {
                if (item.id && selectedSubitems.includes(item.id)) {
                  return (
                    <p
                      key={item.title}
                      style={{
                        fontFamily: "Arial",
                        margin: "5px",
                        fontSize: "1.2rem",
                      }}
                    >
                      {item.title}
                    </p>
                  );
                }
              })
            )
          }
        </div>
        <div style={{ display: "flex" }}>
          <button
            className={cx(
              styles.confirmButton,
              theme.darkMode && styles.darkMode,
            )}
            onClick={deleteProject}
          >
            Confirm
          </button>
          <button
            className={cx(
              styles.cancelButton,
              theme.darkMode && styles.darkMode,
            )}
            onClick={() => setDeleteItemDialog(false)}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <div
        className={cx(styles.popupMenu, theme.darkMode && styles.darkMode)}
        style={{
          display: selectedProjects.length > 0 || selectedSubitems.length ? "block" : "none",
          zIndex: deleteItemDialog ? 1000 : 2000,
        }}
      >
        <div className={styles.popupContainer}>
          <div
            className={styles.optionDisplay}
            style={{
              borderRight: `solid 1px ${theme.darkMode ? "#D0E5F2" : "#dadada"}`,
              marginRight: "110px",
            }}
          >
            {/* projects and subitems can't be selected at the same time, so the number displayed will always be correct, despite the addition operation */}
            <h1 style={{ fontFamily: "PoppinsLight", margin: "-7px" }}>
              {selectedProjects.length +
                selectedSubitems.length}
            </h1>
            <p style={{ margin: 0 }}>Selected</p>
          </div>
          <div
            className={styles.optionDisplay}
            onClick={() => setDeleteItemDialog(true)}
          >
            <img
              src={theme.darkMode ? WhiteTrash : BlackTrash}
              style={{ height: "30px", width: "30px", marginBottom: "5px" }}
            />
            <p style={{ margin: 0 }}>Delete</p>
          </div>
          {/* <div className={styles.optionDisplay}>
            <img
              src={theme.darkMode ? WhiteCopy : BlackCopy}
              style={{ height: "30px", width: "30px", marginBottom: "5px" }}
            />
            <p style={{ margin: 0 }}>Duplicate</p>
          </div> */}
          <div
            onClick={closeMenu}
            className={styles.optionDisplay}
            style={{ position: "absolute", right: 0 }}
          >
            <X style={{ height: "30px", width: "30px", marginBottom: "2px" }} />
            <p style={{ margin: 0 }}>Close</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskTableView;
