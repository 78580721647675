import React, { useState, useContext, useEffect } from 'react';
import styles from './styles/TicketTracking.module.scss';
import { GetTickets } from '../../services/authServices';
import { ThemeContext } from '../../contexts/ThemeContext';
import { EmployeeContext } from '../../contexts/EmployeeContext';
import { AuthContext } from '../../contexts/AuthContext';
import FilterWhite from '../../assets/media/filter-white.png';
import FilterBlack from '../../assets/media/filter-black.png';
import LoadingSpinner from '../../common/components/LoadingSpinner/LoadingSpinner';
import ActiveTicket from './ActiveTicket';
import { Plus } from 'react-feather'
import cx from 'classnames';
import ToastNotification from '../../common/components/ToastNotification';

interface Tickets {
    "all" : Array<any>,
    "active" : Array<any>,
    "resolved" : Array<any>
}

interface ColorCodes {
    "Resolved" : string,
    "Ready for Review" : string,
    "Unresolved" : string
}

const TicketTrackingView = () => {
    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState<keyof Tickets>("all");
    const [tickets, setTickets] = useState<Tickets>({
        "all" : [],
        "active" : [],
        "resolved" : [],
    });    
    const [activeTicket, setActiveTicket] = useState(null);        
    const theme = useContext(ThemeContext);
    const employees = useContext(EmployeeContext).employees;  
    const auth = useContext(AuthContext).user;
    const colorCodes: ColorCodes = {
        "Resolved" : "#2e9d2e",
        "Ready for Review" : "#40bad3",
        "Unresolved" : "#ee4a11"
    }

    useEffect(() => {
        const getTickets = async () => {
            const newTickets: any = await GetTickets();  
            
            setActiveTicket(newTickets[0]);

            setTickets({
                all: newTickets,
                active: newTickets.filter((ticket: any) => ticket.Status !== 'Resolved'),
                resolved: newTickets.filter((ticket: any) => ticket.Status == 'Resolved'),
            });
            setLoading(false);   
        }

        getTickets();
    }, [])

    const createNewTicket = () => {
        var newDate = new Date();
        var day = newDate.getDate();
        var month = newDate.toLocaleDateString("default", { month: "short" });
        var year = newDate.getFullYear();        

        var dateString = `${month} ${day}, ${year}`;        

        const newTicket: any = {
            Id: null,
            CreatedBy: auth[0],
            Status: "Select Status",
            Description: "",
            DateCreated: dateString,
            Photos: [],
        }

        setActiveTicket(newTicket);        
    }

    const cancelNewTicket = () => {
        setActiveTicket(tickets.all[0])
    }

    const updateAllTickets = (newTicket: any) => {        
        if (newTicket.Status !== 'Resolved') {
            setTickets({
                "all" : [newTicket, ...tickets["all"]],
                "active" : [newTicket, ...tickets["active"]],
                "resolved" : tickets["resolved"]
            })
        }
        else if (newTicket.Status == "Resolved") {
            setTickets({
                "all" : [newTicket, ...tickets["all"]],
                "active" : tickets["active"],
                "resolved" : [newTicket, ...tickets["active"]]
            })
        }
    }

    return (
        <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
            <div className={cx(styles.scrollSection, theme.darkMode && styles.darkMode)}>
              <div className={cx(styles.scrollBarOptions, theme.darkMode && styles.darkMode)}>
                <div 
                    className={cx(styles.newTicketButton, theme.darkMode && styles.darkMode)}
                    onClick={() => createNewTicket()}
                >
                    <Plus style={{ height: '17px', width: '17px'}} />
                </div>
                {/* <img src={theme.darkMode ? FilterWhite : FilterBlack} alt="filter"/> */}
                <select onChange={(e) => setDisplay(e.currentTarget.value as keyof Tickets)} className={cx(styles.filterSelect, theme.darkMode && styles.darkMode)}>
                    <option value="all">All</option>
                    <option value="active">Active</option>
                    <option value="resolved">Resolved</option>
                </select>
              </div>
              <div style={{ height: "95%", overflowY: "scroll", scrollbarWidth: 'none' }}>
                {
                    tickets[display].map((ticket: any) => {
                        const employee = employees.find((employee: any) => employee.id == ticket.CreatedBy);

                        let status: keyof ColorCodes = ticket.Status

                        return (
                            <div 
                                key={ticket.Id} 
                                className={cx(styles.ticketItem, theme.darkMode && styles.darkMode)}
                                style={{ borderLeft: `solid 4px ${colorCodes[status]}`}}
                                onClick={() => setActiveTicket(ticket)}
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <p style={{ color: 'gray' }}>{ticket.DateCreated}</p>
                                <img src={employee.photo} />
                            </div>
                                <p>
                                    {
                                        ticket.Description.length > 95 ? (
                                            ticket.Description.slice(0, 95) + '...'
                                        ) : (
                                            ticket.Description
                                        )
                                    }
                                </p>
                            </div>
                        )
                    })
                }
              </div>
            </div>
            <div className={cx(styles.activeTicketContainer, theme.darkMode && styles.darkMode)}>
                <div style={{ height: "5%" }}>
                    <h1>Solutions Tickets</h1>
                </div>
                {
                    loading ? (
                        <div style={{ height: "80%", width: '100%', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                            <div style={{ marginTop: '-100px' }}>
                                <LoadingSpinner />
                            </div>
                        </div>
                    ) : (                        
                        activeTicket == null ? (
                            <div style={{ height: "80%", width: '100%', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                                <p style={{ marginTop: '-100px' }}>No Tickets Currently</p>
                            </div>
                        ) : (
                            <ActiveTicket ticket={activeTicket} employees={employees} cancelNewTicket={cancelNewTicket} updateAllTickets={updateAllTickets} />
                        )                    
                    )
                }
            </div>  
        </div>
    )
}

export default TicketTrackingView