import React, { useContext, useEffect, useState } from "react";
import styles from "./Database.module.scss";
import { ThemeContext } from "../../contexts/ThemeContext";
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Menu,
  Checkbox,
} from "@mui/material";
import BlackTriangle from "../../assets/media/black-triangle.png";
import BlueTriangle from "../../assets/media/blue-triangle.png";
import cx from "classnames";
import ContractButton from "./ContractButton";

const ContractsTable = ({
  filters,
  columns,
  rows,
  height,
  width,
  padding,
  handleFilterChange,
}) => {
  const [open, setOpen] = useState(
    Array(Object.entries(columns).length).fill(false),
  );
  var theme = useContext(ThemeContext);

  const handleMenu = (e, columnIndex) => {
    setOpen((prev) =>
      prev.map((isOpen, index) => (index === columnIndex ? !isOpen : false)),
    );
  };

  const handleClose = (event) => {
    const isOutsideMenu = !event.path.some(
      (element) =>
        element.classList && element.classList.includes(styles.filterMenu),
    );

    if (isOutsideMenu) {
      setOpen(Array(columns.length).fill(false));
    }
  };

  const filterChange = (filter, item, minOrMax = null) => {
    handleFilterChange(filter, item, minOrMax);
  };

  const filterMenu = (column) => {
    if (column.name == "Contract Id") {
      // return (
      //     <div className={cx(styles.contractInput, theme.darkMode && styles.darkMode)}>
      //         <p>Search contract:</p>
      //         <input type="text" onChange={(e) => filterChange("ContractId", e.target.value)}/>
      //     </div>
      // )
    } else if (column.id == "StartDate" || column.id == "DateOfReview") {
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      return (
        <div
          style={{
            padding: "5px",
            display: "flex",
            height: "200px",
            overflowY: "hidden",
          }}
        >
          <div
            style={{
              marginRight: "20px",
              height: "100%",
              overflowY: "scroll",
              padding: "15px",
            }}
          >
            {months.map((month) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={filters[column.id].months.includes(month)}
                    onChange={() => handleFilterChange(column.id, month)}
                  />
                  <p>{month}</p>
                </div>
              );
            })}
          </div>
          <div style={{ height: "100%", overflowY: "scroll", padding: "15px" }}>
            {Array.from({ length: 25 }, (_, index) => {
              var year = 2024 - index;
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={filters[column.id].months.includes(year)}
                    onChange={() => handleFilterChange(column.id, year)}
                  />
                  <p>{year}</p>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (
      column.id == "MaxFees" ||
      column.id == "AnnualNetSpend" ||
      column.id == "ContractScore"
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
          <div style={{ marginRight: "10px" }}>
            <label style={{ marginRight: "5px" }}>Min</label>
            <input
              onChange={(e) =>
                filterChange(column.id, e.currentTarget.value, "min")
              }
              style={{
                backgroundColor: "transparent",
                border: "solid 1px #000000",
              }}
              type="number"
              value={filters[column.id].min}
            />
          </div>
          <div>
            <label style={{ marginRight: "5px" }}>Max</label>
            <input
              onChange={(e) =>
                filterChange(column.id, e.currentTarget.value, "max")
              }
              style={{
                backgroundColor: "transparent",
                border: "solid 1px #000000",
              }}
              type="number"
              value={filters[column.id].max}
            />
          </div>
        </div>
      );
    } else if (column.filter.length > 1) {
      return (
        <ul>
          {column.filter.map((item, index) => {
            return (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={
                    filters[column.id] != undefined &&
                    filters[column.id].includes(item)
                  }
                  onChange={() => filterChange(column.id, item)}
                />
                {item}
              </div>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <div className={cx(styles.table, theme.darkMode && styles.darkMode)}>
      <TableContainer
        sx={{ padding: 0, maxHeight: height, minHeight: "50vh", width: width }}
        id="scrollable-container"
      >
        <Table sx={{ borderCollapse: "separate" }}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 999 }}>
            <TableRow>
              {Object.entries(columns).map(([key, value], i) => {
                var test = [];
                rows.forEach((row) => {
                  if (row[key] !== null) {
                    test.push(row[key]);
                  }
                });

                return (
                  <TableCell
                    key={key}
                    style={{
                      padding: padding,
                      color: "inherit",
                      backgroundColor: theme.darkMode ? "#323130" : "#FFFFFF",
                      borderBottom: theme.darkMode
                        ? "solid 0.5px #7A7978"
                        : "solid 1px rgb(180, 180, 180)",
                    }}
                    className={
                      i == 0
                        ? cx(
                            styles.tableCellFirstCol,
                            theme.darkMode && styles.darkMode,
                          )
                        : styles.tableCell
                    }
                  >
                    <div
                      className={styles.columnButton}
                      onClick={(e) => handleMenu(e, i)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ margin: "0 4px 0 0" }}>{value.name}</p>
                        {filters[key] !== undefined && filters[key].length && (
                          <div className={styles.filterAlert}>
                            <p>
                              {filters[key] !== undefined &&
                                filters[key].length}
                            </p>
                          </div>
                        )}
                      </div>
                      {value.filter &&
                        value.filter.length > 1 &&
                        key != "ContractId" &&
                        key != "ContractUrl" &&
                        test.length > 0 && (
                          <img
                            src={theme.darkMode ? BlueTriangle : BlackTriangle}
                            style={{ height: "10px", width: "10px" }}
                          />
                        )}
                    </div>
                    {key == "MaxFees" ||
                    key == "AnnualNetSpend" ||
                    key == "ContractScore" ||
                    key == "StartDate" ||
                    key == "DateOfReview" ? (
                      <div
                        style={{ display: open[i] ? "block" : "none" }}
                        className={cx(
                          styles.filterMenu,
                          theme.darkMode && styles.darkMode,
                        )}
                        onClose={handleClose}
                      >
                        {filterMenu(value)}
                      </div>
                    ) : (
                      key !== "ClientId" &&
                      key !== "ContractId" &&
                      key !== "ContractUrl" &&
                      test.length > 0 && (
                        <div
                          style={{ display: open[i] ? "block" : "none" }}
                          className={cx(
                            styles.filterMenu,
                            theme.darkMode && styles.darkMode,
                          )}
                          onClose={handleClose}
                        >
                          {value.filter && filterMenu(value)}
                        </div>
                      )
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, index) => {
                return (
                  <TableRow key={row.ContractId}>
                    {Object.entries(row).map(([key, value], i) => {
                      var editHref =
                        i == 0
                          ? `/dashboard/contracts/add-edit-contract?id=${value}`
                          : "";
                      if (columns[key]) {
                        // to exclude columns we don't want on the table
                        if (
                          (key == "AnnualNetSpend" || key == "MaxFees") &&
                          value
                        ) {
                          value = `${value.toLocaleString()}`;
                        }

                        if (key == "ContractUrl") {
                          if (theme.darkMode) {
                            return (
                              <TableCell
                                key={i}
                                style={{
                                  padding: padding,
                                  borderBottom: "solid 0.5px #525150",
                                  backgroundColor:
                                    index % 2 == 0 ? "#252423" : "#323130",
                                  color: "inherit",
                                }}
                                className={
                                  i == 0
                                    ? styles.tableCellFirstCol
                                    : styles.tableCell
                                }
                              >
                                {value == "N/A" ? (
                                  <p style={{ margin: 0, marginRight: "18px" }}>
                                    {value}
                                  </p>
                                ) : (
                                  <ContractButton link={value} />
                                )}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                key={value}
                                style={{
                                  padding: padding,
                                  backgroundColor:
                                    index % 2 == 0 ? "#EEEEEE" : "#FFFFFF",
                                }}
                                className={
                                  i == 0
                                    ? styles.tableCellFirstCol
                                    : styles.tableCell
                                }
                              >
                                {value == "N/A" ? (
                                  <p style={{ margin: 0, marginRight: "18px" }}>
                                    {value}
                                  </p>
                                ) : (
                                  <ContractButton link={value} />
                                )}
                              </TableCell>
                            );
                          }
                        } else {
                          if (theme.darkMode) {
                            return (
                              <TableCell
                                key={i}
                                style={{
                                  padding: padding,
                                  borderRight:
                                    i == 0 ? "solid 0.5px #7A7978" : "none",
                                  borderBottom: "solid 0.5px #525150",
                                  backgroundColor:
                                    index % 2 == 0 ? "#252423" : "#323130",
                                  color: "inherit",
                                }}
                                className={
                                  i == 0
                                    ? styles.tableCellFirstCol
                                    : styles.tableCell
                                }
                              >
                                {i == 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{ margin: 0, marginRight: "18px" }}
                                    >
                                      {value}
                                    </p>
                                    <a
                                      href={editHref}
                                      className={styles.editButton}
                                    >
                                      <button>Edit</button>
                                    </a>
                                  </div>
                                ) : (
                                  <p style={{ margin: 0 }}>{value}</p>
                                )}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                key={i}
                                style={{
                                  padding: padding,
                                  backgroundColor:
                                    index % 2 == 0 ? "#EEEEEE" : "#FFFFFF",
                                }}
                                className={
                                  i == 0
                                    ? styles.tableCellFirstCol
                                    : styles.tableCell
                                }
                              >
                                {i == 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{ margin: 0, marginRight: "18px" }}
                                    >
                                      {value}
                                    </p>
                                    <a
                                      href={editHref}
                                      className={styles.editButton}
                                    >
                                      <button>Edit</button>
                                    </a>
                                  </div>
                                ) : (
                                  <p style={{ margin: 0 }}>{value}</p>
                                )}
                              </TableCell>
                            );
                          }
                        }
                      }
                    })}
                  </TableRow>
                );
              })
            ) : (
              <p style={{ margin: "0 auto" }}>No Data to Display</p>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ContractsTable;
