import React, { useState, createContext, useContext, ReactNode } from "react";

type Toast = {
    message: string,
    status: "Loading" | "Success" | "Error",
};

type ToastContextValue = {
    toast: Toast | null,
    showToast: (message: string, status: "Loading" | "Success" | "Error") => void
};

export const ToastContext = createContext<ToastContextValue | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [toast, setToast] = useState<Toast | null>(null);

    const showToast = (message: string, status: "Loading" | "Success" | "Error") => {
        setToast({ message, status });

        setTimeout(() => {
          setToast(null);
        }, 3000);
    };

    return (
        <ToastContext.Provider value={{toast, showToast}}>
            {children}
        </ToastContext.Provider>
    )
}

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
      throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};
