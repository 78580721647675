import React, { useState, useContext, useEffect } from "react";
import styles from "./ToastNotification.module.scss"
import { ThemeContext } from "../../../contexts/ThemeContext";
import { ToastContext } from "../../../contexts/ToastContext";
import { useToast } from "../../../contexts/ToastContext";
import cx from 'classnames'
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { Check, X } from 'react-feather';

const ToastNotification = () => {
    const { toast } = useToast();
    const theme = useContext(ThemeContext);

    if (!toast) {
        return null
    }
    else {
        const icon = () => {
            switch (toast.status) {
                case "Loading":
                    return <LoadingSpinner />
                case "Success":
                    return <Check style={{ color: "#2e9d2e" }} />
                case "Error":
                    return <X style={{ color: "#cc0202" }} />
                default: 
                    return null
            }
        }
    
        return (
            <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
                <div style={{ marginRight: '15px'}}>
                    {icon()}
                </div>
                <p>{toast.message}</p>                
            </div>
        )
    }

}

export default ToastNotification