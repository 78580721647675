import React from 'react'
import ScriptsView from '../features/scripts'

const Scripts = () => {
  return (
    <ScriptsView />
  )
}

export default Scripts
