import React, { useContext, useState } from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import { Drawer, Dialog } from '@mui/material';
import { UploadItemFiles, DeleteItemFiles, AddProcessNote } from '../../services/authServices';
import styles from './styles/ProcessTable.module.scss'
import cx from 'classnames'

interface Props {
    sidebarDisplay: number,
    setSidebarDisplay: Function,
    title: string,
    itemFiles: any,
    id: number,
    itemId: number,
    subSubItemId: number | null
    setItemFilesHelper: Function
}  

const FileSidebar: React.FC<Props> = ({ sidebarDisplay, setSidebarDisplay, title, itemFiles, id, itemId, subSubItemId, setItemFilesHelper }) => {
  const [files, setFiles] = useState<any>(itemFiles)
  const [filesToUpload, setFilesToUpload] = useState<Array<any>>([]);
  const [uploadLink, setUploadLink] = useState<boolean>(false); 
  const [fileLink, setFileLink] = useState<string>("");
  const [linkFileName, setLinkFileName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [filesToDelete, setFilesToDelete] = useState<Array<number>>([]);
  const theme = useContext(ThemeContext);

  const selectFile = () => {
    setUploadLink(false);
    setFileLink("");
    var input = document.createElement("input");
    input.type = "file";
    input.multiple = true;

    input.onchange = (_) => {
        let files = input.files;
        if (files) {
            var arr = Array.from(files);
            setFilesToUpload(arr);
        }        
    };

    input.click();
  }

  const uploadFiles = async () => {
    setLoading(true);
    if (fileLink != "" && uploadLink) {
        await UploadItemFiles(fileLink, id, "link", sidebarDisplay, linkFileName, "dbo.ProcessFiles", null, subSubItemId)
        .then((res: any) => res.json())
        .then((data: any) => {
            let newFiles: any = [];
    
            Object.keys(data).forEach((fileId: any) => {
                newFiles.push({ ...data[fileId], Id: fileId, ItemId: itemId  })
            })
                
            setFiles([...files, ...newFiles])  
            setItemFilesHelper(id, itemId, [...files, ...newFiles]);
            setLoading(false);          
            setUploadLink(false);
            setFilesToUpload([])
        })
    }
    else if (filesToUpload !== null) {
        await UploadItemFiles(filesToUpload, id, "files", sidebarDisplay, null, "dbo.ProcessFiles", null, subSubItemId)
        .then((res: any) => res.json())
        .then((data: any) => {
            let newFiles: any = []

            Object.keys(data).forEach((fileId: any) => {
                newFiles.push({ ...data[fileId], Id: fileId, ItemId: itemId  })
            })
            
            setFiles([...files, ...newFiles]);            
            setItemFilesHelper(id, itemId, [...files, ...newFiles]);
            setLoading(false);
            setFilesToUpload([])
        })
    }
  }

  const removeFile = (fileName: string) => {
    var newFiles = filesToUpload!.filter((file) => file.name !== fileName);

    setFilesToUpload(newFiles);
  }

  const deleteFiles = async () => {
    var res: any = await DeleteItemFiles(filesToDelete, "dbo.ProcessFiles")    
    
    if (res.status == 200) {
        let newArr = files.filter((file: any) => !filesToDelete.includes(file.Id))
        setFiles(newArr)
        setItemFilesHelper(id, itemId, newArr);
        setFilesToDelete([])
    }
  }

  const updateDeleteFiles = (id: number) => {    
    var newArr: Array<number> = []
    if (filesToDelete.includes(id)) {
        newArr = filesToDelete.filter(fileId => fileId !== id);        
    }
    else {
        newArr = [...filesToDelete, id]
    }
    setFilesToDelete(newArr)
  }


  return (
    <div>
      <Drawer
            anchor="right"
            open={sidebarDisplay !== -1}
            onClose={() => setSidebarDisplay(-1)}
            PaperProps={{
                sx: {
                    width: "35vw",
                    backgroundColor: theme.darkMode ? "#323130" : "#FFFFFF",
                    color: theme.darkMode ? "#FFFFFF" : "#000000",
                    overflow: 'hidden'
                },
            }}
        >
            <div className={styles.drawerContainer}>
            <div style={{ borderBottom: 'solid 1px #cacaca', padding: '20px' }}>
                {
                    sidebarDisplay == 0 ? (
                        <>
                            <h1>{title}</h1>
                            <h2>{files.length} File{files.length !== 1 && 's'}</h2>
                        </>
                    ) : (
                        <>
                            <h1 style={{ marginBottom: '10px' }}>{title}</h1>
                            <h2>Instructions</h2>
                        </>
                    )
                }
                    {
                        filesToUpload.length == 0 ? (
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: uploadLink ? '20px' : 0 }}>
                                <button onClick={selectFile} style={{ marginRight: '10px' }}>New {sidebarDisplay == 0 ? "Files" : "Instructions" } +</button>                                                                    
                                <p onClick={() => setUploadLink(prev => !prev)} className={styles.uploadLink}>Or add a link to a file</p>                            
                            </div>
                        ) : (
                            <div>
                                <p style={{ fontWeight: 800, fontSize: '1.2rem', margin: '0 0 15px 0' }}>Files to upload:</p>
                                {
                                    filesToUpload.map((file: any) => {                                
                                        
                                        return (
                                            <div key={file.Name} style={{ margin: '7px 0', display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{ fontWeight: 300, fontSize: '0.9rem', margin: 0 }}>{file.name}</p>
                                                <button id={styles.removeButton} onClick={() => removeFile(file.name)}>Remove</button>
                                            </div>
                                        )
                                    })
                                }
                                <div style={{ marginTop: '20px' }}>
                                    <button 
                                        style={{ marginRight: '15px', fontSize: '0.9rem' }}
                                        onClick={uploadFiles}
                                    >
                                        Upload
                                    </button>
                                    <button 
                                        style={{ fontSize: '0.9rem', backgroundColor: '#9C0E0E' }}
                                        onClick={() => setFilesToUpload([])}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    {
                        uploadLink && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <input 
                                        value={fileLink} 
                                        className={styles.linkInput} 
                                        style={{ margin: '0 10px 10px 0' }} 
                                        placeholder="Paste link here"
                                        onChange={(e) => setFileLink(e.currentTarget.value)}
                                    />                                
                                    <input 
                                        value={linkFileName} 
                                        className={styles.linkInput} 
                                        style={{ marginRight: '10px' }} 
                                        placeholder="Add a name for this file"
                                        onChange={(e) => setLinkFileName(e.currentTarget.value)}
                                    /> 
                                </div>
                                <button onClick={uploadFiles} style={{ backgroundColor: fileLink !== "" ? "#000000" : "#EFEFEF" }}>Upload</button>
                            </div>
                        )
                    }
            </div>
            <div style={{ padding: '20px' }}>                
                {
                    files.length > 0 && (
                        files.map((file: any) => {
                            var date = new Date(file.DateAdded);
                            var day = date.getDate();
                            var month = date.toLocaleString('default', { month: 'short' });                            
                            var stringDate = `${month} ${day}, ${date.getFullYear()}`  
                            
                            if (file.Category == sidebarDisplay) {
                                return (
                                    <div key={file.Id} style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <input checked={filesToDelete.includes(file.Id)} onChange={() => updateDeleteFiles(file.Id)} type="checkbox" style={{ height: '15px', width: '15px', marginRight: '10px' }} />
                                        <div>
                                            <a target="_blank" className={styles.fileLink} href={file.Url}>{file.FileName.length > 40 ? `${file.FileName.slice(0, 40)}...` : file.FileName}</a>
                                            <p style={{ margin: "5px 0 0 0", fontStyle: 'italic', fontSize: '0.85rem', color: "#959595" }}>Uploaded: {stringDate}</p>
                                        </div>
                                    </div>
                                )
                            }

                        })
                    )
                }                
                {
                    filesToDelete.length > 0 && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p>Delete selected files?</p>
                            <div>
                                <button onClick={deleteFiles} style={{ backgroundColor: "#9C0E0E", fontSize: '0.85rem' }}>Delete</button>
                                <button onClick={() => setFilesToDelete([])} style={{ fontSize: '0.85rem', marginLeft: '15px' }}>Cancel</button>
                            </div>
                        </div>
                    )
                }
                {
                    loading && (
                        <div style={{ width: "100%", textAlign: 'center' }}>
                            <span className={cx(styles.loadingSpinner, theme.darkMode && styles.darkMode)}></span>
                        </div>
                    )
                }
            </div>
          </div>
        </Drawer>
    </div>
  )
}

export default FileSidebar
