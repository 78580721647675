import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import styles from "./Overview.module.scss";
import { Dialog } from "@mui/material";
import { CreateTaskChart, GetTaskCharts } from "../../services/authServices";
import TaskTile from "../../common/components/TaskTile/TaskTile";
import cx from "classnames";

interface Table {
  title: string;
  author: string;
  photo: string;
}

const TaskManagerOverviewView = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const [errors, setErrors] = useState(false);  
  const [taskCharts, setTaskCharts] = useState<Table[]>([]);
  const [visibleTaskCharts, setVisibleTaskCharts] = useState<Table[]>([]);
  const [displayAll, setDisplayAll] = useState<boolean>(false);
  const context = useContext(AuthContext).user;
  const [copyChart, setCopyChart] = useState(false);
  const [chartToCopy, setChartToCopy] = useState<number | null>(null);
  const theme = useContext(ThemeContext);

  const getAllCharts = async () => {
    var tasks: any = [];
    await GetTaskCharts().then((res: any) => {
      tasks = [...res];
    });

    let sortedByDate = tasks.sort((a: any, b: any) => new Date(b[3]).getTime() - new Date(a[3]).getTime());    
    setVisibleTaskCharts(sortedByDate.slice(0, 5))
    setTaskCharts(sortedByDate);
  };

  useEffect(() => {
    getAllCharts();
  }, []);

  const createTable = async (e: any) => {    
    e.preventDefault();
    if (newTaskTitle.trim() == "") {
      setErrors(true);
    } else {
      setErrors(false);      
      await CreateTaskChart(
        newTaskTitle,
        `${context[1]} ${context[2]}`,
        `${new Date().toString().slice(0, 15)}`,
        context[6],
        chartToCopy,
      ).then((res) => {        
        setOpenCreateDialog(false);
        getAllCharts()
      }) 
      // title, name, date
    }
  };

  const updateTitle = (e: any) => {
    setNewTaskTitle(e.target.value);
  };  

  const displayAllCharts = () => {
    setDisplayAll(true);
    setVisibleTaskCharts(taskCharts);
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)} id="scrollable-container">
      <Dialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        sx={{
          "& .MuiPaper-root": {
            height: "72vh",
            width: "35vw",
            padding: "20px 40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            boxSizing: "border-box",
          },
        }}
      >
        <h1 className={styles.formTitle}>Create new chart</h1>
        <div style={{ width: "100%" }}>
          <form>
            <ul>
              <li>
                <label>Title</label>
                <input
                  className={styles.newChartInput}
                  value={newTaskTitle}
                  onChange={(e) => updateTitle(e)}
                />
              </li>
              <li>
                <div
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <label style={{ margin: "0 0 5px 0" }}>
                      Copy Existing Chart
                    </label>
                    <p style={{ margin: 0, fontSize: "0.75rem" }}>
                      Tasks carrying over? Move all projects into next week.
                    </p>
                  </div>
                  <input
                    checked={copyChart}
                    type="checkbox"
                    style={{ height: "20px", width: "20px" }}
                    onChange={() => setCopyChart((prev) => !prev)}
                  />
                </div>
                {copyChart && (
                  <select
                    defaultValue="Select"
                    className={styles.selectMenu}
                    onChange={(e) =>
                      setChartToCopy(parseInt(e.currentTarget.value))
                    }
                  >
                    <option disabled>Select</option>
                    {taskCharts.map((chart: any) => {
                      return <option value={chart[0]}>{chart[1]}</option>;
                    })}
                  </select>
                )}
              </li>
              <li>
                <label>Author</label>
                <input
                  className={styles.newChartInput}
                  value={`${context[1]} ${context[2]}`}
                  style={{ color: "#969696" }}
                  disabled
                />
              </li>
              <li style={{ marginBottom: "50px" }}>
                <label>Date Created</label>
                <input
                  className={styles.newChartInput}
                  value={`${new Date().toString().slice(0, 15)}`}
                  style={{ color: "#969696" }}
                  disabled
                />
              </li>
              {errors && (
                <li>
                  <p style={{ margin: "0 auto", color: "rgb(203, 10, 10)" }}>
                    Please add a title.
                  </p>
                </li>
              )}
              <li>
                <button
                  type="submit"
                  className={styles.submitButton}
                  onClick={(e) => createTable(e)}
                >
                  Create
                </button>
              </li>
            </ul>
          </form>
        </div>
      </Dialog>
      <div className={styles.headerDiv}>
        <h1 className={styles.header}>Task Manager</h1>
        <button
          className={cx(styles.createNew, theme.darkMode && styles.darkMode)}
          onClick={() => setOpenCreateDialog(true)}
        >
          Create new
        </button>
      </div>
      <h2>Recent</h2>
      <div className={styles.tileContainer}>
        {visibleTaskCharts.length ? (
          visibleTaskCharts.map((task: any) => {            
            return (
              <TaskTile
                id={task[0]}
                title={task[1]}
                author={task[2]}
                authorPhoto={task[4]}
                href={`/dashboard/task-manager/${task[0]}`}
                getAllCharts={getAllCharts}
              />
            );
          })
        ) : (
          <p>Loading</p>
        )}        
      </div>
      {
        !displayAll && (
          <div onClick={() => displayAllCharts()} className={styles.expand}>
            <div className={styles.line}></div>
            <p>See All</p>
            <div className={styles.line}></div>
          </div>
        )
      }
    </div>
  );
};

export default TaskManagerOverviewView;
