import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { AuthContext } from '../../contexts/AuthContext';
import { EmployeeContext } from '../../contexts/EmployeeContext';
import styles from "./styles/ProcessesIndex.module.scss";
import cx from 'classnames';
import { Plus, Filter, Shuffle, X } from 'react-feather';
import { Dialog } from '@mui/material';
import { NewProcessTable, GetProcessTables, DeleteProcessItem, GetUserAbilities } from '../../services/authServices'
import BlackArrow from '../../assets/media/arrow-left.png';
import WhiteArrow from '../../assets/media/arrow-white.png';
import WhiteTrash from "../../assets/media/white-trash.png";
import BlackTrash from "../../assets/media/trash-black.png";
import ProcessTable from './ProcessTable';

const ProcessesView = () => {
  const [newDialog, setNewDialog] = useState(false);
  const [newTableTitle, setNewTableTitle] = useState("");
  const [processes, setProcesses] = useState<any>([]);
  const [activeProcess, setActiveProcess] = useState<any | null>(null);
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const [deleteItems, setDeleteItems] = useState<any>(true);
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext).user;
  const employees = useContext(EmployeeContext).employees;

  useEffect(() => {
    const getTables = async () => {
      var newTables: any = await GetProcessTables();
      setProcesses(newTables)      
      var userAbilities: any = await GetUserAbilities(14);
      userAbilities.data.forEach((item: any) => {        
        if (item.Id == 1) {
          if (auth[7] !== 2 && !item.Status) setDeleteItems(false);
        }
      })      
    }
    getTables()
  }, [])

  const createNewTable = async () => {
    if (newTableTitle !== "") {
      await NewProcessTable(newTableTitle, auth[0])
      .then((res: any) => {
        if (res.status == 200) {
          window.location.reload();
        }
      })
    }
  }

  const handleProcessView = (e: any, id: number | null) => {
    if (e.target.id !== "checkbox") {
      if (id == null) {
        let currTable = document.getElementById("process-display");
        let newTable = document.getElementById("processes-table");
        currTable?.classList.add(styles.fadeOutRight);
  
        setTimeout(() => {
          currTable!.style.display = "none";
          setActiveProcess(null)
        }, 100)      
  
        setTimeout(() => {
          newTable!.style.display = "block"
    
          newTable?.classList.remove(styles.fadeOut);            
          newTable!.classList.add(styles.fadeIn);
        }, 100)
      }
      else {
        let table = document.getElementById("processes-table");
        let process = processes.find((process: any) => process.Id == id)
        if (table !== null) {
          table.classList.add(styles.fadeOut); 
          setTimeout(() => table!.style.display = "none", 200)
          setTimeout(() => setActiveProcess(process), 200)
        }    
      }
    }
  }

  const handleCheckedItems = (id: number) => {
    if (checkedItems.includes(id)) {
      let newItems = checkedItems.filter((processId: number) => processId !== id);
      setCheckedItems(newItems)
    }
    else {
      setCheckedItems([...checkedItems, id])
    }
  }

  const deleteProcessTable = async () => {
    await DeleteProcessItem(checkedItems, "table")
    .then((res: any) => {
      if (res.status == 200) {
        let newTables = processes.filter((process: any) => !checkedItems.includes(process.Id));
        setProcesses(newTables);
        setCheckedItems([])
      }
    })
  }

  const setItemsHelper = (newItems: any) => {
    let newProcesses = processes.filter((process: any) => process.Id !== newItems.Id);
    let sorted = [...newProcesses, newItems].sort((a: any, b: any) => a.Id - b.Id);    
    setProcesses(sorted);
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)} id="scrollable-container">
      <div className={cx(styles.topBar, theme.darkMode && styles.darkMode)}>
        <h1>Abintus Processes</h1>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={cx(styles.optionDiv, theme.darkMode && styles.darkMode)} onClick={() => setNewDialog(true)}>
            <p>New</p>
            <Plus style={{ height: '15px', width: '15px', marginBottom: '-1px' }} />
          </div>
          {/* <div className={cx(styles.optionDiv, theme.darkMode && styles.darkMode)}>
            <p>Filter</p>
            <Filter style={{ height: '15px', width: '15px', marginBottom: '-1px' }} />
          </div>
          <div className={cx(styles.optionDiv, theme.darkMode && styles.darkMode)}>
            <p>Sort</p>
            <Shuffle style={{ height: '15px', width: '15px', marginBottom: '-1px' }} />
          </div>               */}
        </div>
      </div>
      <div className={cx(styles.tableDisplay, theme.darkMode && styles.darkMode)} id="processes-table">
        <div className={cx(styles.table, theme.darkMode && styles.darkMode)} id="scrollable-container">          
          <div className={cx(styles.tableRow, theme.darkMode && styles.darkMode)}>
            {
              auth[7] == 2 && (
                <div style={{ width: '5%', borderRight: 'solid 1px #cacaca' }}></div>
              )
            }
            <div style={{ width: '35%', fontWeight: 'bold', color: "#878787", borderRight: 'solid 1px #cacaca' }}><p>Title</p></div>
            <div style={{ width: '15%', fontWeight: 'bold', color: "#878787", borderRight: 'solid 1px #cacaca' }}><p>Content</p></div>
            <div style={{ width: '20%', fontWeight: 'bold', color: "#878787", borderRight: 'solid 1px #cacaca' }}><p>Created</p></div>
            <div style={{ width: '25%', fontWeight: 'bold', color: "#878787" }}><p>Author</p></div>
          </div>
          {
            processes.length > 0 && (
              processes.map((process: any) => {     
                let creator = employees.find((employee: any) => employee.id == process.Creator);  
                let photo = creator.photo;      
                
                let date = new Date(process.DateCreated);
                let month = date.toLocaleDateString("default", { month: "short" });
                let year = date.getFullYear();
                let day = date.getDate();

                let dateString = `${month} ${day}, ${year}`;                

                return (
                  <div key={process.Id} className={cx(styles.tableRow, theme.darkMode && styles.darkMode)} onClick={(e) => handleProcessView(e, process.Id)}>
                    {
                      auth[7] == 2 && (
                        <div style={{ width: '5%'}}>
                          <input onChange={() => handleCheckedItems(process.Id)} type="checkbox" checked={checkedItems.includes(process.Id)} id="checkbox" />
                        </div>
                      )
                    }
                    <div style={{ width: '35%' }}><p>{process.Title}</p></div>
                    <div style={{ width: '15%' }}><p>{process.Items.length} Item{process.Items !== 1 && 's'}</p></div>
                    <div style={{ width: '20%' }}><p>{dateString}</p></div>
                    <div style={{ width: '25%', display: 'flex', alignItems: 'center' }}><img className={styles.pfp} src={photo} alt="photo" /><p>{creator.name}</p></div>
                  </div>
                )
              })
            )
          }
        </div>
      </div>
      {
        activeProcess !== null && (
          <div className={cx(styles.processDisplay, theme.darkMode && styles.darkMode)} id="process-display">            
            <h1 style={{ margin: "0 0 10px 0", fontSize: "1.5rem" }}>{activeProcess.Title} - <span style={{ fontWeight: 200}}>{activeProcess.Items.length} Items</span></h1>            
            <div className={styles.backButton} onClick={(e) => handleProcessView(e, null)}>
              <img src={theme.darkMode ? WhiteArrow : BlackArrow} style={{ height: '20px', width: '20px', margin: '0 10px -2px 0' }} alt="arrow" />
              <p style={{ margin: 0 }}>Back</p>
            </div>
            <ProcessTable activeProcess={activeProcess} employees={employees} setItemsHelper={setItemsHelper} deleteItems={deleteItems} />
          </div>
        )
      }
      {/* dialogs will be here */}
      <Dialog
        open={newDialog}
        onClose={() => setNewDialog(false)}
        sx={{
          "& .MuiPaper-root": {
            width: "60vw",
            padding: "30px 40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: 'center',            
          },
        }}
      >
        <h1 className={styles.dialogHeader}>Create New Process Chart</h1>        
          <div className={styles.dialogSection}>
            <p>Title</p>
            <input type="text" value={newTableTitle} onChange={(e) => setNewTableTitle(e.currentTarget.value)} />
          </div>
          <div className={styles.dialogSection} style={{ marginBottom: '50px' }}>
            <p>Created By</p>
            <input type="text" value={`${auth[1]} ${auth[2]}`} disabled />
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <button className={styles.formButton} style={{ backgroundColor: "#000000" }} onClick={() => createNewTable()}>Create</button>
            <button className={styles.formButton} style={{ backgroundColor: "#9C0E0E" }} onClick={() => setNewDialog(false)}>Cancel</button>
          </div>
      </Dialog>
      <div className={styles.popupMenu} style={{ display: checkedItems.length > 0 ? "flex" : "none" }}>
        <div style={{ display: 'flex', width: '48%', alignItems: 'center' }}>
          <div style={{ marginRight: "30px", textAlign: 'center', padding: "10px 0", borderRight: "solid 1px #cacaca", height: "100%", width: '50%', boxSizing: 'border-box' }}>
            <h1 style={{ margin: 0 }}>{checkedItems.length}</h1>
            <p style={{ margin: 0 }}>Selected</p>
          </div>
          <div onClick={() => deleteProcessTable()} style={{ textAlign: 'center'}} className={styles.deleteProcesses}>
            <img src={BlackTrash} style={{ height: "35px", width: "35px" }} />
            <p style={{ margin: 0 }}>Delete</p>
          </div>
        </div>
        <div onClick={() => setCheckedItems([])} style={{ textAlign: 'center', width: '24%' }} className={styles.deleteProcesses}>
          <X style={{ height: '35px', width: '35px' }} />
          <p style={{ margin: 0 }}>Close</p>
        </div>
      </div>
    </div>
  )
}

export default ProcessesView
