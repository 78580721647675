import React from 'react'
import DashboardDemoView from '../features/dashboardDemo'

const DashboardDemo = () => {
  return (
    <DashboardDemoView />
  )
}

export default DashboardDemo
