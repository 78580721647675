import React, { useContext } from 'react'
import { ThemeContext } from '../../../contexts/ThemeContext'
import styles from "./LoadingSpinner.module.scss"
import cx from 'classnames'

const LoadingSpinner = () => {
    const theme = useContext(ThemeContext)

    return (
        <span className={cx(styles.loadingSpinner, theme.darkMode && styles.darkMode)}></span>
    )
}

export default LoadingSpinner