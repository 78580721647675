import React, { useContext } from 'react'
import styles from './styles/DashboardDemo.module.scss'
import { ThemeContext } from '../../contexts/ThemeContext'
import cx from 'classnames'

const DashboardDemo2View = () => {
  const theme = useContext(ThemeContext);

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)} id="scrollable-container">      
    </div>
  )
}

export default DashboardDemo2View
