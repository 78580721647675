import React from 'react'
import DashboardDemo2View from '../features/dashboardDemo/index2'

const DashboardDemo2 = () => {
  return (
    <DashboardDemo2View />
  )
}

export default DashboardDemo2
