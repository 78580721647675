import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from "react-router-dom";
import { ThemeContext } from '../../contexts/ThemeContext';
import { GetOnboardingChart, CreateOnboardingTableAndChart, CreateOnboardingItem, DeleteOnboardingItems, ChangeOnboardingOrder } from '../../services/authServices';
import styles from "./OnboardingId.module.scss";
import cx from "classnames";
import TableTitle from './TableTitle';
import Title from './Title';
import Person from './Person';
import Status from './Status';
import Files from './Files';
import ChevronDown from "../../assets/media/chevron-down-white.png";
import { Menu } from "@mui/material";
import { randomColor } from "../../common/helpers/randomColor";
import { todaysDate } from "../../common/helpers/todaysDate";
import BlackTrash from "../../assets/media/trash-black.png";
import BlackArrow from "../../assets/media/black-arrow.png";
import Chevron from '../../assets/media/chevron-down.png';
import ChevronWhite from '../../assets/media/chevron-down-white.png';
import StartDate from './StartDate';

interface Chart {
  name: string,
  date: string,
  // tables: Array<Table>,
}

interface Table {
  id: number,
  title: string,
  items: Array<any>,
  color: string
}

interface Item {
  Id: number,
  Title: string,
  Person: string | null,
  Status: string | null,
  StatusColor: string | null,
  Priority: string | null,
  PriorityColor: string | null,
  StartDate: string,
  Documents: Array<any>,
}

const OnboardingId = () => {
  const [chart, setChart] = useState<Chart | null>(null);  
  const [tables, setTables] = useState<Array<Table>>([]);  
  const [tableAnchorEl, setTableAnchorEl] = useState<any>(null);
  const [newTableTitle, setNewTableTitle] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);
  const [openTableMenu, setOpenTableMenu] = useState<number | null>(null);
  const [drag, setDrag] = useState<number | null>(null);
  const [dragOver, setDragOver] = useState<number | null>(null);
  const [draggedItem, setDraggedItem] = useState<number | null>(null);

  const theme = useContext(ThemeContext);
  const id = useParams().onboardingId;

  const getChart = async () => {
    if (id) {
      var chart: any = {
        name: "",
        date: ""
      }

      await GetOnboardingChart(id, "dbo.OnboardingCharts")
      .then((res: any) => {         
                                
        let date = new Date(res[0].DateCreated);        
        let month = date.toLocaleDateString('default', { month: 'long' })
        let day = date.getDate();
        let year = date.getFullYear();

        chart.date = `${month} ${day}, ${year}`
        chart.name = res[0].Employee;        
      });


      setChart(chart);

      var items: any = [];
      await GetOnboardingChart(id, "dbo.OnboardingItems")
      .then((res: any) => {            
        res.forEach((item: any) => {
          items.push(item);          
        })
      })      

      items.sort((a: any, b: any) => a.OrderId - b.OrderId);      

      var tables: Array<Table> = [];
      await GetOnboardingChart(id, "dbo.OnboardingTables")
      .then((res: any) => {        
        res.reverse().forEach((table: any) => {          
          tables.push({
            id: table.Id,
            title: table.Title,
            color: table.Color,
            items: items.filter((item: any) => item.TableId == table.Id),
          })
        })        
      });

      setTables(tables);
    }
  }

  const newTable = async () => {    
    if (newTableTitle != "" && id) {
      var color = randomColor();

      await CreateOnboardingTableAndChart(newTableTitle, id, color, null)
      .then((res: any) => {
        if (res == "OK") {
          window.location.reload();
        }
      })
    }
  }

  useEffect(() => {
    getChart()
  }, []);  

  const createItem = async (e: any, tableId: number) => {
    if (e.key == "Enter" && id) {      
      var value = e.currentTarget.value

      e.currentTarget.value = "";

      let table = tables.find((table: any) => table.id == tableId);
      let lastItem = table?.items[table.items.length - 1];
      let orderId = lastItem ? lastItem.OrderId + 1 : 0;      

      var newId = await CreateOnboardingItem(value, id, tableId.toString(), orderId);      

      var newItem = {
        Id: newId,
        Title: value,
        Person: null,
        Status: null,
        StatusColor: null,
        Priority: null,
        PriorityColor: null,
        StartDate: new Date().toISOString(),
        Files: null,
        OrderId: orderId,
        Documents: []
      }      

      var newTables = tables.map((table: Table) => {
        if (table.id == tableId) {          
          return {
            ...table,
            items: [...table.items, newItem]
          }
        }
        else {
          return table
        }
      });

      setTables(newTables);
    }
  }

  const handleSelected = (id: number) => {
    let newIds;
    if (!selectedItems.includes(id)) {
      newIds = [...selectedItems, id]
      setSelectedItems(newIds);
    }
    else {
      newIds = selectedItems.filter((item: number) => item !== id);
      setSelectedItems(newIds);
    }
  }

  const deleteItems = async () => {
    if (selectedItems.length > 0) {

      var newTables = tables.map((table: Table) => {
        return {
          ...table,
          items: table.items.filter((item) => !selectedItems.includes(item.Id))
        }
      });      

      setTables(newTables);

      var stringified = selectedItems.join(",");
      setSelectedItems([]);
      await DeleteOnboardingItems(stringified);
    }
  }

  const deleteTable = async (tableId: number) => {
    await DeleteOnboardingItems(tableId, "dbo.OnboardingTables")
    .then((res: any) => {
      if (res.data == "OK") {
        var newTables = tables.filter((table: any) => table.id !== tableId);
        setTables(newTables);
      }
    })
  }

  const changeNewTableTitle = (e: any) => {     
    setNewTableTitle(e.currentTarget.value)
  }

  const handleDragDrop = () => {
    const items = tables.map((table: any) => table.items).flat();    
    const movedItem = items.find((item: any) => item.Id == drag);
    const adjacentItem = items.find((item: any) => item.Id == dragOver);

    movedItem.TableId = adjacentItem.TableId // for when item is moved to another table

    let newTest = items.filter((item: any) => item.Id !== drag);
    let adjacentRowIndex = items.findIndex((item: any) => item.Id == dragOver);
    let movedRowIndex = items.findIndex((item: any) => item.Id == movedItem.Id)    

    if (movedRowIndex < adjacentRowIndex) {
      newTest.splice(adjacentRowIndex, 0, movedItem);
    }
    else {
      newTest.splice(adjacentRowIndex + 1, 0, movedItem);
    }

    let orderArray: any = []; // for updating database
    newTest = newTest.map((item: any, i: number) => {
      orderArray.push({Id: item.Id, OrderId: i, TableId: item.TableId})
      return {
        ...item,
        OrderId: i
      }
    })    

    // console.log(newTest)

    // let test = [];
    

    // test.sort((a: any, b: any) => a.OrderId - b.OrderId);
    // console.log({test});

    // let newItems = items.map((item: any) => {
    //   if (item.Id == drag) {
    //     orderArray.push({ Id: item.Id, TableId: adjacentItem.TableId, OrderId: adjacentItem.OrderId + 1 })
    //     return {
    //       ...item,
    //       OrderId: adjacentItem.OrderId + 1,
    //       TableId: adjacentItem.TableId
    //     }
    //   }
    //   else if (item.OrderId > adjacentItem.OrderId) {
    //     orderArray.push({ Id: item.Id, TableId: item.TableId, OrderId: adjacentItem.OrderId + 1 })
    //     return {
    //       ...item,
    //       OrderId: item.OrderId + 1
    //     }
    //   }
    //   else {
    //     return item
    //   }
    // })
    
    let newTables = tables.map((table: any) => {
      return {
        ...table,
        items: newTest.filter((item: any) => item.TableId == table.id)
      }
    })

    ChangeOnboardingOrder(orderArray);
    setDraggedItem(drag);
    setDrag(null);
    setDragOver(null);
    setTables(newTables);
  } 

  // useEffect(() => {
  //   console.log(tables)
  // }, [tables])

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <div className={cx(styles.titleInfo, theme.darkMode && styles.darkMode)}>
        {
          chart && (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <h1 style={{ marginBottom: "5px" }}>Employee Onboarding - {chart.name}</h1>
                  <p>Created: {chart.date}</p>
                  <a style={{ color: theme.darkMode ? "#fff" : "#000", fontSize: "0.75rem" }} href="/dashboard/onboarding">Back</a>
                </div>
                <div className={cx(styles.options, theme.darkMode && styles.darkMode)}>                  
                  <div
                    className={styles.newTableMenu}                    
                    id="new-table-menu"                   
                  >                                                                            
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input onChange={(e) => changeNewTableTitle(e)} placeholder="Create New Table" value={newTableTitle} className={styles.newTableInput} style={{ color: theme.darkMode ? '#FFFFFF' : "#000000" }} />                      
                      <button onClick={() => newTable()} className={styles.createTableButton}>Create</button>
                    </div>                        
                  </div>
                </div>
              </>
          )
        }
      </div>
      <div style={{ paddingLeft: "40px" }}>
        {
          chart && (
            tables.sort((a: any, b: any) => a.id - b.id).map((table: any) => {                  
              return (
                <div style={{ marginBottom: "40px" }} key={table.id}>
                  <div className={styles.tableTopBar}>
                    <div 
                      onClick={(e) => {
                        setOpenTableMenu(table.id)
                        setTableAnchorEl(e.currentTarget);
                      }}
                      className={styles.dotMenu} 
                      style={{ height: '15px', padding: "0 5px", marginRight: '10px' }}
                    >
                      <div style={{ backgroundColor: `${table.color}`}}></div>
                      <div style={{ backgroundColor: `${table.color}`}}></div>
                      <div style={{ backgroundColor: `${table.color}`}}></div>
                    </div>
                    <Menu
                      open={openTableMenu == table.id}
                      anchorEl={tableAnchorEl}
                      onClose={() => {
                        setOpenTableMenu(null);
                        setTableAnchorEl(null);
                      }}
                      sx={{
                        "& .MuiPaper-root": {                                                                                                                         
                          padding: "7px 15px",                        
                          borderRadius: "5px",                                                   
                        },
                      }}
                    >
                      <div className={styles.tableDelete} onClick={() => deleteTable(table.id)}>
                        <img src={BlackTrash} />
                        <p style={{ margin: 0 }}>Delete Table</p>
                      </div>
                    </Menu>
                    {/* <input value={table.title} style={{ color: table.color }} className={styles.tableTitle}/> */}
                    <TableTitle title={table.title} color={table.color} itemId={table.id} />
                  </div>
                  <div className={cx(styles.tableContainer, theme.darkMode && styles.darkMode)} style={{ borderLeft: `solid 2px ${table.color}` }}>
                    <div className={styles.tableRow}>
                      <div className={cx(styles.headerCell, theme.darkMode && styles.darkMode)} style={{ width: "30%" }}><p>Item</p></div>
                      <div className={cx(styles.headerCell, theme.darkMode && styles.darkMode)} style={{ width: "10%" }}><p>Person</p></div>
                      <div className={cx(styles.headerCell, theme.darkMode && styles.darkMode)} style={{ width: "13%" }}><p>Status</p></div>
                      <div className={cx(styles.headerCell, theme.darkMode && styles.darkMode)} style={{ width: "13%" }}><p>Priority</p></div>
                      <div className={cx(styles.headerCell, theme.darkMode && styles.darkMode)} style={{ width: "12%" }}><p>Date</p></div>
                      <div className={cx(styles.headerCell, theme.darkMode && styles.darkMode)} style={{ width: "10%" }}><p>Files</p></div>
                      <div className={cx(styles.headerCell, theme.darkMode && styles.darkMode)} style={{ width: "10%" }}><p>Instructions</p></div>
                      <div className={cx(styles.headerCell, theme.darkMode && styles.darkMode)} style={{ width: "3.5%" }}></div>
                    </div>
                    {
                      table.items.map((item: Item) => {                                    
                        let files = item.Documents.filter((doc: any) => doc.Category == 1);
                        let instructions = item.Documents.filter((doc: any) => doc.Category == 2);                                   
                        let date = item.StartDate == null ? null : item.StartDate.split("T")[0]

                        return (
                          <>
                            <div 
                              className={cx(styles.tableRow, drag == item.Id && styles.dragged, draggedItem == item.Id && styles.pulse)} 
                              key={item.Id}
                              draggable={true}
                              onDrag={() => setDrag(item.Id)}
                              onDragOver={(e) => setDragOver(item.Id)}
                              onDragEnd={() => handleDragDrop()}  
                            >
                              <div className={cx(styles.titleCell, theme.darkMode && styles.darkMode)} style={{ width: "30%" }}>
                                <input checked={selectedItems.includes(item.Id)} onChange={() => handleSelected(item.Id)} type="checkbox" style={{ marginRight: "8px", height: "15px", width: "15px" }} />
                                <Title title={item.Title} itemId={item.Id}/>                              
                              </div>                            
                              <Person person={item.Person} itemId={item.Id} />
                              <Status itemId={item.Id} status={item.Status} color={item.StatusColor} type="Status" />
                              <Status itemId={item.Id} status={item.Priority} color={item.PriorityColor} type="Priority" />                            
                              <StartDate date={date} itemId={item.Id} />
                              <Files itemTitle={item.Title} itemId={item.Id} documents={files} category={1} tableId={table.id}/>
                              <Files itemTitle={item.Title} itemId={item.Id} documents={instructions} category={2} tableId={table.id}/>
                              <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ width: "3.5%" }}></div>
                            </div>
                            <div
                              className={cx(
                                  styles.dropZone,
                                  dragOver == item.Id &&
                                      drag !== item.Id &&
                                      styles.active,
                                  )}
                              style={{
                                backgroundColor: theme.darkMode ? "rgba(25, 25, 25, 0.83)" : "#f4f4f4",
                              }}
                            ></div>
                          </>
                        )
                      })
                    }
                    <div className={cx(styles.newItemRow, theme.darkMode && styles.darkMode)}>
                      <div style={{ padding: '6px', width: "32%", display: "flex", boxSizing: 'border-box', alignItems: "center" }}>
                        <input type="checkbox" disabled style={{ marginRight: "8px", height: "15px", width: "15px" }} />
                        <input onKeyUp={(e) => createItem(e, table.id)} className={styles.newItemInput} placeholder="Add New Item"/>
                      </div>                      
                    </div>
                  </div>
                </div>
              )
            })
          )
        }        
      </div>
      {
        selectedItems.length > 0 && (
          <div className={cx(styles.selectMenu, theme.darkMode && styles.darkMode)}>
            <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
              <div style={{ height: '100%', width: '6rem', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRight: 'solid 2px #f4f4f4', marginRight: "30px" }}>
                <p style={{ fontSize: '2rem' }}>{selectedItems.length}</p>
                <p>Selected</p>
              </div>
              <div onClick={() => deleteItems()} className={styles.option} style={{ display: "flex", flexDirection: 'column', alignItems: 'center', marginRight: '20px' }}>
                <img src={BlackTrash} alt="trash" style={{ marginBottom: '5px', height: '2.2rem', width: '2.2rem' }} />
                <p style={{ fontSize: '0.9rem' }}>Delete</p>
              </div>
              <div className={styles.option} style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                <img src={BlackArrow} alt="trash" style={{ marginBottom: '5px', height: '2.2rem', width: '2.2rem' }} />
                <p style={{ fontSize: '0.9rem' }}>Change Table</p>
              </div>
            </div>
            <div 
              onClick={() => setSelectedItems([])}
              className={styles.option}
              style={{ height: '100%', width: '6rem', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderLeft: 'solid 2px #f4f4f4' }}
            >
              <p style={{ fontSize: '1.8rem' }}>X</p>
              <p>Close</p>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default OnboardingId
