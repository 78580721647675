import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import { AuthProvider } from "./contexts/AuthContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { EmployeeProvider } from "./contexts/EmployeeContext";
import { ToastProvider } from "./contexts/ToastContext";
import DashboardLayout from "./common/layouts";
import IdleTimer from "./common/components/IdleTimer/IdleTimer";

// test feature branch

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <ThemeProvider>
          <EmployeeProvider>
            <BrowserRouter>            
              <AppRoutes />
            </BrowserRouter>
          </EmployeeProvider>
        </ThemeProvider>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
