import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { AuthContext } from "../../contexts/AuthContext";
import { GetUserProjects, GetStatuses, GetAllUsers, GetChartProjects } from '../../services/authServices';
import styles from "./TaskInfo.module.scss";
import PhotoCell from '../../common/components/PhotoCell/PhotoCell';
import StatusCell from '../../common/components/StatusCell/StatusCell';
import TextCell from '../../common/components/TextCell/TextCell';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cx from 'classnames';
import AnonPhoto from "../../assets/media/anon.jpeg";


const TaskInfo = () => {
  const [projects, setProjects] = useState([])
  const [statuses, setStatuses] = useState<any>([])
  const [users, setUsers] = useState<any>([]);
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext)
  const priority = ["Critical", "High"];
  
  const getStatuses = async () => {
    const res: any = await GetStatuses("dbo.Statuses");            

    setStatuses(res);
  }

  useEffect(() => {
    getStatuses()
  }, [])

  const getUsers = async () => {
    var users: any = await GetAllUsers();
    setUsers(users);
  }

  useEffect(() => {
    getUsers()
  }, [])

  const getProjects = async () => {        
    var test: any = await GetChartProjects(null);        

    var testSorted = test.sort((a: any, b: any) => priority.indexOf(a[5]) - priority.indexOf(b[5]));    
    var unique: any = [];    
    testSorted.forEach((item: any) => {        
      if (item[12] != 'Completed') {
        var test = unique.map((title: any) => title[2]);
        
        if (!test.includes(item[2])) {
          unique.push(item);
        }
      }
    })
        
    setProjects(unique);
  }  

  useEffect(() => {
    getProjects();
  }, [])

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>        
      <div className={cx(styles.itemColumns, theme.darkMode && styles.darkMode)} id="scrollable-container">
        <div className={styles.row} id={styles.titleRow} style={{ backgroundColor: theme.darkMode ? "#32313058" : "#ffffff5a" }}>
            <div id={styles.firstCell} className={cx(styles.itemCell, theme.darkMode && styles.darkMode)}><h1 style={{ fontSize: '0.85rem' }}>Projects</h1></div>
            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '8.4%' }}><h1>Sponsor</h1></div>
            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '8.4%' }}><h1>Manager</h1></div>
            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '14.5%' }}><h1>Priority</h1></div>
            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '14.5%' }}><h1>Status</h1></div>
            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '14.5%' }}><h1>Key Client</h1></div>
            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '14.5%' }}><h1>Start Date</h1></div>
        </div>
          {
            statuses.length > 0 && projects.length > 0 && projects.map((item, index) => {                                        
              var priorityColor = '';

              if (item[5] == "Critical") {
                priorityColor = "rgb(203, 10, 10)";
              }
              else if (item[5] == "High") {
                priorityColor = "#EB731B"
              }
              else if (item[5] == "Medium") {
                priorityColor = "#EAD712"
              }
              else if (item[5] == "Low") {
                priorityColor = "#16B994"
              }
              else if (item[5] == "Always On") {
                priorityColor = "#3874F5"
              }
                            
              var sponsorUser = users.find((x: any) => x.name == item[3]);
              var sponsorPhoto = sponsorUser == undefined ? AnonPhoto : sponsorUser.photo;

              var managerUser = users.find((x: any) => x.name == item[4]);
              var managerPhoto = managerUser == undefined ? AnonPhoto : managerUser.photo;

              return (
                <div className={styles.row}>
                  <div id={styles.firstCell} className={cx(styles.itemCell, theme.darkMode && styles.darkMode)}>
                    <p>{item[2]}</p>
                  </div>
                  <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '8.4%' }}>
                    <img src={sponsorPhoto} style={{ height: "30px", width: '30px', borderRadius: '50px', backgroundPosition: 'center', objectFit: 'cover' }} />
                  </div>
                  <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '8.4%' }}>
                    <img src={managerPhoto} style={{ height: "30px", width: '30px', borderRadius: '50px', backgroundPosition: 'center', objectFit: 'cover' }} />
                  </div>                    
                  <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '14.5%' }}>
                    <strong><p style={{ color: priorityColor }}>{item[5]}</p></strong>
                  </div>
                  <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '14.5%' }}>
                    <p>{item[6]}</p>
                  </div>                  
                  <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '14.5%' }}>
                      {/* <TextCell chartId={null} projectId={null} subitemId={null} isTitle={false} comments={null} subitemCount={null} titleRef={null} value={item[7]} column={"KeyClient"} /> */}
                    <p>{item[7]}</p>
                  </div> 
                  <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '14.5%' }}>
                      {/* <DatePicker selected={item[8]} onChange={() => console.log("hello")}/> */}
                      <p>{item[8]}</p>                      
                  </div> 
                </div>
              )
            })
          }
        </div>
    </div>
  )
}

export default TaskInfo
