import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AdminRoute from "./AdminRoute";
import Home from "../pages/Home";
import RequestAccess from "../pages/RequestAccess";
import VerifyEmail from "../pages/VerifyEmail";
import NotFound from "../pages/404";
import Dashboard from "../pages/Dashboard";
import AllBenchmarks from "../pages/AllBenchmarks";
import { AuthContext } from "../contexts/AuthContext";
import AccountSettings from "../pages/AccountSettings";
import AddBenchmarks from "../pages/AddBenchmarks";
import PowerBI from "../pages/PowerBI";
import ForgotPassword from "../pages/ForgotPassword";
import DashboardLayout from "../common/layouts";
import ContractDatabase from "../pages/ContractDatabase";
import ContractDashboard from "../pages/ContractDashboard";
import ContractAddEdit from "../pages/ContractAddEdit";
import AVB from "../pages/AVB";
import MPQ from "../pages/MPQ";
import WFAInflation from "../pages/WFAInflation";
import TaskManagerOverview from "../pages/TaskManagerOverview";
import TaskTable from "../pages/TaskTable";
import AllAutomations from "../pages/AllAutomations";
import UnfinishedPage from "../common/components/UnfinishedPage";
import PitchStatistics from "../pages/PitchStatistics";
import LeadManagement from "../pages/LeadManagement";
import VacationBoard from "../pages/VacationBoard";
import OnboardingIndex from "../pages/Onboarding";
import OnboardingView from "../pages/OnboardingView";
import Accounts from "../pages/Accounts";
import Processes from "../pages/Processes";
import { GetAllowedPages } from '../services/authServices';
import TrackingDemo from "../pages/TrackingDemo";
import Contacts from "../pages/Contacts";
import Scripts from "../pages/Scripts";
import DashboardDemo from "../pages/DashboardDemo";
import DashboardDemo2 from "../pages/DashboardDemo2";
import TicketTracking from '../pages/TicketTracking'

const AppRoutes = () => {
  const [allowedPages, setAllowedPages] = useState()
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const getPages = () => {
      GetAllowedPages()
      .then((res: any) => {
        setAllowedPages(res);
      })
    }

    getPages()
  }, [])

  if (allowedPages) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/request-access" element={<RequestAccess />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
        // protected routes
        <Route
          path="/verify-email"
          element={
            <ProtectedRoute route="/verify-email">
              <VerifyEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute route="/dashboard">
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/contracts/contract-database"
          element={
            <ProtectedRoute route="/dashboard/contracts/contract-database">
              <AdminRoute allowedPages={allowedPages} page="ContractDatabase">
                <DashboardLayout>
                  <ContractDatabase />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/contracts/contract-dashboard"
          element={
            <ProtectedRoute route="/dashboard/contracts/contract-dashboard">
              <AdminRoute allowedPages={allowedPages} page="ContractDashboard">
                <DashboardLayout>
                  <ContractDashboard />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/contracts/add-edit-contract"
          element={
            <ProtectedRoute route="/dashboard/contracts/add-edit-contract">   
              <AdminRoute allowedPages={allowedPages} page="AddEditContracts">
                <DashboardLayout>
                  <ContractAddEdit />
                </DashboardLayout>                          
              </AdminRoute>         
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/benchmarks/avbs"
          element={
            <ProtectedRoute route="/dashboard/benchmarks/avbs">
              <AdminRoute allowedPages={allowedPages} page="Benchmarks">
                <DashboardLayout>
                  <AVB />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/benchmarks/price-and-quality"
          element={
            <ProtectedRoute route="/dashboard/benchmarks/price-and-quality">
              <AdminRoute allowedPages={allowedPages} page="Benchmarks">
                <DashboardLayout>
                  <MPQ />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/benchmarks/technical-costs"
          element={
            <ProtectedRoute route="/dashboard/benchmarks/technical-costs">
              <AdminRoute allowedPages={allowedPages} page="Benchmarks">
                <DashboardLayout>
                  <UnfinishedPage />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/benchmarks/remunerations"
          element={
            <ProtectedRoute route="/dashboard/benchmarks/remunerations">
              <AdminRoute allowedPages={allowedPages} page="Benchmarks">
                <DashboardLayout>
                  <UnfinishedPage />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/benchmarks/wfa-inflation"
          element={
            <ProtectedRoute route="/dashboard/benchmarks/wfa-inflation">
              <AdminRoute allowedPages={allowedPages} page="Benchmarks">
                <DashboardLayout>
                  <WFAInflation />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/automations"
          element={
            <ProtectedRoute route="/dashboard/automations">
              <DashboardLayout>
                <AllAutomations />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/automations-demo"
          element={
            <ProtectedRoute route="/dashboard/automations-demo">
              <DashboardLayout>
                <AllAutomations />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/all-benchmarks"
          element={
            <ProtectedRoute route="/dashboard/all-benchmarks">
              <DashboardLayout>
                <AllBenchmarks />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/add-benchmarks"
          element={
            <ProtectedRoute route="/dashboard/add-benchmarks">
              <DashboardLayout>
                <AddBenchmarks />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/account-settings"
          element={
            <ProtectedRoute route="/dashboard/account-settings">
              <DashboardLayout>
                <AccountSettings />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/powerbi-dashboards"
          element={
            <ProtectedRoute route="/dashboard/powerbi-dashboards">
              <DashboardLayout>
                <PowerBI />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/task-manager/overview"
          element={
            <ProtectedRoute route="/dashboard/task-manager/overview">
              <DashboardLayout>
                <TaskManagerOverview />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/task-manager/:chartId"
          element={
            <ProtectedRoute route="/dashboard/task-manager/:chartId">
              <DashboardLayout>
                <TaskTable />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/pitch-statistics"
          element={
            <ProtectedRoute route="/dashboard/pitch-statistics">
              <DashboardLayout>
                <PitchStatistics />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/lead-management/:chartId"
          element={
            <ProtectedRoute route="/dashboard/lead-management/:chartId">
              <AdminRoute allowedPages={allowedPages} page="LeadManagement">
                <DashboardLayout>
                  <LeadManagement />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/vacation-board"
          element={
            <ProtectedRoute route="/dashboard/vacation-board">
              <AdminRoute allowedPages={allowedPages} page="VacationBoard">
                <DashboardLayout>
                  <VacationBoard />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/onboarding"
          element={
            <ProtectedRoute route="/dashboard/onboarding">
              <AdminRoute allowedPages={allowedPages} page="Onboarding">
                <DashboardLayout>
                  <OnboardingIndex />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/onboarding/:onboardingId"
          element={
            <ProtectedRoute route="/dashboard/onboarding/:onboardingId">
              <AdminRoute allowedPages={allowedPages} page="Onboarding">
                <DashboardLayout>
                  <OnboardingView />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/solutions-accounts"
          element={
            <ProtectedRoute route="/dashboard/solutions-accounts">
              <AdminRoute allowedPages={allowedPages} page="Accounts">
                <DashboardLayout>
                  <Accounts />
                </DashboardLayout>
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/processes"
          element={
            <ProtectedRoute route="/dashboard/processes">
              {/* <AdminRoute allowedPages={allowedPages} page="Accounts"> */}
                <DashboardLayout>
                  <Processes />
                </DashboardLayout>
              {/* </AdminRoute> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/tracking-demo"
          element={
            <ProtectedRoute route="/dashboard/tracking-demo">
              {/* <AdminRoute allowedPages={allowedPages} page="Accounts"> */}
                <DashboardLayout>
                  <TrackingDemo />
                </DashboardLayout>
              {/* </AdminRoute> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/demo-2"
          element={
            <ProtectedRoute route="/dashboard/demo-2">
              {/* <AdminRoute allowedPages={allowedPages} page="Accounts"> */}
                <DashboardLayout>
                  <DashboardDemo2 />
                </DashboardLayout>
              {/* </AdminRoute> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/dashboard-demo-pyramid"
          element={
            <ProtectedRoute route="/dashboard/dashboard-demo-pyramid">
              {/* <AdminRoute allowedPages={allowedPages} page="Accounts"> */}
                <DashboardLayout>
                  <DashboardDemo />
                </DashboardLayout>
              {/* </AdminRoute> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/media-agency-contacts"
          element={
            <ProtectedRoute route="/dashboard/media-agency-contacts">
              {/* <AdminRoute allowedPages={allowedPages} page="Accounts"> */}
                <DashboardLayout>
                  <Contacts />
                </DashboardLayout>
              {/* </AdminRoute> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/se-tracking-scripts"
          element={
            <ProtectedRoute route="/dashboard/se-tracking-scripts">
              {/* <AdminRoute allowedPages={allowedPages} page="Accounts"> */}
                <DashboardLayout>
                  <Scripts />
                </DashboardLayout>
              {/* </AdminRoute> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/ticket-tracking"
          element={
            <ProtectedRoute route="/dashboard/ticket-tracking">
              {/* <AdminRoute allowedPages={allowedPages} page="Accounts"> */}
                <DashboardLayout>
                  <TicketTracking />
                </DashboardLayout>
              {/* </AdminRoute> */}
            </ProtectedRoute>
          }
        />
      </Routes>
    );
  }
  else {
    return null;
  }
};

export default AppRoutes;
