import React, { useState, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./Sidebar.module.scss";
import ChevronWhite from "../../assets/media/chevron-white.png";
import ChevronBlack from "../../assets/media/chevron-black.png";
import cx from "classnames";
import { CreateNewLeadChart } from "../../services/authServices";
import { useLocation, useNavigate } from "react-router-dom";

interface props {
  title: string;
  iconLight: string;
  iconDark: string;
  children: any;
}

const SidebarChildren = (props: props) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext).user;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const createNewLeadChart = async () => {    
    await CreateNewLeadChart(`${auth[1]} ${auth[2]}`, auth[7])
    .then((res: any) => {            
      navigate(`/dashboard/lead-management/${res.id}`)
      window.location.reload();    
    })
  }

  return (
    <div className={cx(styles.subitemChildrenWrapper, open && styles.open)}>
      <div
        onClick={() => setOpen((prev) => !prev)}
        className={styles.sidebarSubitem}
        style={{
          marginBottom: "10px",
          justifyContent: "space-between",
          paddingRight: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={theme.darkMode ? props.iconLight : props.iconDark}
            alt="icon"
          />
          <p>{props.title}</p>
        </div>
        <img
          className={cx(styles.chevron, open && styles.open)}
          src={theme.darkMode ? ChevronWhite : ChevronBlack}
          alt="chevron"
        />
      </div>
      <div
        style={{ backgroundColor: theme.darkMode ? "#191919d4" : "#F0F0F0" }}
      >
        {props.children.map((item: any, index: number) => {

          if (item.title == "+ New") {
            
            return (
              <div key={index} onClick={createNewLeadChart} className={styles.subitemLinks} style={{ padding: "10px 0 10px 30px", borderTop: `solid 1px ${theme.darkMode ? "#4B4948" : "#D3D3D3"}` }}> 
                <p style={{ fontSize: '0.7rem' }}>{item.title}</p>
              </div>
            )
          }
          else {
            return (
              <a href={item.href} className={styles.subitemLinks} key={item.title}>
                <div
                  style={{
                    borderTop:
                      index !== 0
                        ? `solid 1px ${theme.darkMode ? "#4B4948" : "#D3D3D3"}`
                        : "",
                    padding: "10px 0 10px 30px",
                  }}
                >
                  <p style={{ fontSize: '0.7rem' }}>{item.title}</p>                  
                </div>
              </a>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SidebarChildren;
