import React, { useContext, useState } from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import styles from "./styles/ScriptsView.module.scss"
import LoadingSpinner from '../../common/components/LoadingSpinner/LoadingSpinner';
import { Check, X } from 'react-feather';
import { 
  Test,
  InHouseBuy, 
  Reconciliation2024, 
  AgencyBuy, 
  NLAgencyBuy, 
  NLDirectBuy, 
  CompetitiveAnalysis 
} from '../../services/scriptServices'
import cx from 'classnames'
import { start } from 'repl';

const ScriptsView = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const theme = useContext(ThemeContext);

  const startScript = async (script: number, countryCode: string | null = null) => {    
    setStatus("Loading");

    if (script == 1) {
      const res: any = await InHouseBuy();
      console.log(res);
      setStatus(res.Message);
      if (res.Status !== 200) {
        setError(true)
      }
    }
    else if (script == 2) {
      const res: any = await Reconciliation2024();
      console.log(res);
      setStatus(res.Message);
      if (res.Status !== 200) {
        setError(true)      
      }
    }
    else if (script == 3 && countryCode) {
      const res: any = await AgencyBuy(countryCode);
      console.log(res);
      setStatus(res.Message);
      if (res.Status !== 200) {
        setError(true)      
      }
    }
    else if (script == 4) {
      const res: any = await NLAgencyBuy();
      console.log(res);
      setStatus(res.Message);
      if (res.Status !== 200) {
        setError(true)
      }
    }
    else if (script == 5) {
      const res: any = await NLDirectBuy();
      console.log(res);
      setStatus(res.Message);
      if (res.Status !== 200) {
        setError(true)
      }
    }
    else if (script == 6) {
      const res: any = await CompetitiveAnalysis();
      console.log(res);
      setStatus(res.Message);
      if (res.Status !== 200) {        
        setError(true)
      }
    }

    setTimeout(() => {
      setStatus(null)
      setError(false)
    }, 3000)
  }

  const message = () => {
    if (status == "Loading") {
      return (
        // <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-start' }}>
        <>
          <LoadingSpinner />
          <p style={{ margin: '0 0 0 15px' }}>Processing</p>
        </>
        // </div>
      )
    }
    else if (status !== "Loading" && status !== null) {
      return (
        <>
          {
            error ? (
              <X style={{ color: "#cc0202" }}/>
            ) : (
              <Check style={{ color: "#2e9d2e" }} />
            )
          }
          <p style={{ margin: '0 0 0 15px' }}>{status}</p>
        </>
      )
    }
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <div className={cx(styles.subSection, theme.darkMode && styles.darkMode)}>
        <h1>Test</h1>
        <button style={{ margin: '5px' }} onClick={() => Test()}>Test Script</button>
      </div>
      <div className={cx(styles.subSection, theme.darkMode && styles.darkMode)}>
        <h1>In-House Buy</h1>
        <button style={{ margin: '5px' }} onClick={() => startScript(1)}>In-House Buy</button>
      </div>
      {/* <div className={cx(styles.subSection, theme.darkMode && styles.darkMode)}>
        <h1>Reconciliation 2024</h1>
        <button style={{ margin: '5px' }} onClick={() => startScript(2)}>Reconciliation 2024</button>
      </div> */}
      <div className={cx(styles.subSection, theme.darkMode && styles.darkMode)}>
        <h1>Agency Buy</h1>
        <div>
          <button style={{ margin: '5px' }} onClick={() => startScript(3, 'fr')}>France</button>
          <button style={{ margin: '5px' }} onClick={() => startScript(3, 'de')}>Germany</button>
          <button style={{ margin: '5px' }} onClick={() => startScript(3, 'es')}>Spain</button>
        </div>
      </div>
      <div className={cx(styles.subSection, theme.darkMode && styles.darkMode)}>
        <h1>NL Agency Buy</h1>
        <button style={{ margin: '5px' }} onClick={() => startScript(4)}>NL Agency Buy</button>
      </div>
      <div className={cx(styles.subSection, theme.darkMode && styles.darkMode)}>
        <h1>NL Direct Buy</h1>
        <button style={{ margin: '5px' }} onClick={() => startScript(5)}>NL Direct Buy</button>
      </div>
      <div className={cx(styles.subSection, theme.darkMode && styles.darkMode)}>
        <h1>Competitive Analysis</h1>
        <button style={{ margin: '5px' }} onClick={() => startScript(6)}>Competitive Analysis</button>
      </div>
      <div className={cx(styles.toastNotification, status !== null && styles.visible)}>
        {message()}
      </div>
    </div>
  )
}

export default ScriptsView
