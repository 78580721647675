import axios from "axios";
// var url: string = "http://localhost:5000/";
// var url: string = "https://python-scripts-api.vercel.app/"
var url: string = "https://python-scripts-api-gzbme0ccchfyd5hv.uksouth-01.azurewebsites.net/"

export const Test = () => {
    fetch(url + "test")
        .then((res: any) => res.json())
        .then((data) => {
            console.log(data)
        })
}

export const InHouseBuy = () => 
    new Promise(async (resolve, reject) => {
        fetch(url + "in-house-buy")
            .then((res: any) => res.json())
            .then((data) => {                      
                resolve(data);
            })
    })

export const Reconciliation2024 = () => 
    new Promise(async (resolve, reject) => {
        fetch(url + "reconciliation-2024")
            .then((res: any) => res.json())
            .then((data) => {                      
                resolve(data);
            })
    })

export const AgencyBuy = (countryCode: string) => 
    new Promise(async (resolve, reject) => {
        fetch(url + `${countryCode}-agency-buy`)
            .then((res: any) => res.json())
            .then((data) => {                      
                resolve(data);
            })
    })

export const NLAgencyBuy = () => 
    new Promise(async (resolve, reject) => {
        fetch(url + "netherlands-agency-buy")
            .then((res: any) => res.json())
            .then((data) => {                      
                resolve(data);
            })
    })

export const NLDirectBuy = () => 
    new Promise(async (resolve, reject) => {
        fetch(url + "netherlands-direct-buy")
            .then((res: any) => res.json())
            .then((data) => {                      
                resolve(data);
            })
    })

export const CompetitiveAnalysis = () => 
    new Promise(async (resolve, reject) => {
        fetch(url + "competitive-analysis")
            .then((res: any) => res.json())
            .then((data) => {                      
                resolve(data);
            })
    })
