import React from 'react'
import WFAInflationView from '../features/wfaInflation'

const WFAInflation = () => {
  return (
    <WFAInflationView />
  )
}

export default WFAInflation
